import React, { useState } from 'react';
import Grid from '@mui/material/Grid2';
import { Box, Paper, Typography, TextField, Button, InputProps, Divider, IconButton, LinearProgress, InputAdornment, FormControl, InputLabel, Select, MenuItem, FormControlLabel,FormHelperText, Checkbox, Radio, RadioGroup, Switch, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions} from '@mui/material';
import { icons } from '../../Assets/icons'
import { Link } from 'react-router-dom';
import { SelectChangeEvent } from '@mui/material/Select';
import styles from "./Login.module.scss";
import ProgressBar from './ProgressBar/ProgressBar';
import { useNavigate } from 'react-router-dom';


const EnterYourAddress = () => {
    const navigate = useNavigate();
    const confirmYourAddress = () => {
        navigate('/confirm-your-address'); 
    };
  return (
    <Grid container className={styles.loginBg}>
        <Grid size={6} className={styles.wrapContent}>            
            <div className={styles.formBox}>
                <div className={styles.backBtn}><img src={icons.backBtn} alt="" /></div>  
                <ProgressBar />   
                <div className={styles.logoMobile}><img src={icons.glam9Logo} alt="" /></div> 
                <div className={`${styles.formContent} ${styles.searchAddress}`}>
                    <div className={styles.searchTop}>
                        <div className={styles.titleBox}>
                            <h1>Enter Your Address</h1>
                            <p>Where can clients find you?</p>
                        </div>
                        <div className={`${styles.inputBox} ${styles.searchInput}`}>
                            <TextField placeholder="Search" variant="outlined" fullWidth className={styles.inputBx} />                        
                            <img src={icons.searchIcon} className={styles.searchIcon} />
                        </div>
                    </div>
                    <div className={styles.btnWrap}>
                        <Link to="">Can’t find your address?</Link>
                        <Button variant="contained" className={styles.btn} onClick={confirmYourAddress}>Add Location +</Button> 
                    </div>
                </div> 
            </div> 
        </Grid>
        <Grid size={6} className={styles.companyBg}>
            <img src={icons.glam9Logo} alt='' />
        </Grid>        
    </Grid>
  )
}

export default EnterYourAddress



