// Assuming images is an object where keys are strings and values are string paths to images.
export const images: { [key: string]: string } = {
    LoginLogo: require('./login_logo.png'),
    LogoBg: require('./logoBg.png'),
    Logo: require('./Logo.png'),
    Google: require('./google.png'),
    Facebook: require('./facebook.png'),
    Apple: require('./apple.png'),
    leftArrow: require('./left_arrow.png'),
    check: require('./check.png'),
    Checked: require('./checked.png'),
    Cancel: require('./cancel.png'),    
    LocationMap: require('./locationMap.png')
};
