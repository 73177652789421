import React from 'react';
import Grid from '@mui/material/Grid2';
import { Box, Paper, Typography, TextField, Button, InputProps, Divider, IconButton, LinearProgress, InputAdornment, FormControl, InputLabel, Select, MenuItem, FormControlLabel,FormHelperText, Checkbox, Radio, RadioGroup, Switch, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions} from '@mui/material';
import { icons } from '../../Assets/icons'
import ProgressBar from './ProgressBar/ProgressBar';
import styles from "./Login.module.scss";
import { useNavigate } from 'react-router-dom';


const AddServices = () => {
    const navigate = useNavigate();
    const helpOne = () => {
      navigate('/help-one'); // Navigate to the "About" page
    };
  return (
    <Grid container className={styles.loginBg}>
        <Grid size={6} className={styles.wrapContent}>            
            <div className={`${styles.formBox} ${styles.formBigBox}`}>
                <div className={styles.backBtn}><img src={icons.backBtn} alt="" /></div>  
                <ProgressBar />   
                <div className={styles.logoMobile}><img src={icons.glam9Logo} alt="" /></div> 
                <div className={`${styles.formContent} ${styles.formBigContent}`}>
                    <div className={styles.titleBox}>
                        <h1>Start Adding services</h1>       
                        <p>Add at least one service now. Later you can add more,
                        edit details, and group services into categories.</p>             
                    </div>    
                    <div className={styles.servicesWrap}>
                        <div className={styles.serviceList}>
                            <div className={styles.serviceName}>
                                <div className={styles.deleteIcon}>
                                    <img src={icons.deleteService} />
                                </div>
                                <span>Hair Cut</span>
                            </div>
                            <div className={styles.time}>30 min</div>
                            <div className={styles.price}>
                                <span>₹30.00</span>
                                <div className={styles.hoursArrow}>
                                    <img src={icons.hoursArrow} />
                                </div>
                            </div>
                        </div> 
                        <div className={styles.serviceList}>
                            <div className={styles.serviceName}>
                                <div className={styles.deleteIcon}>
                                    <img src={icons.deleteService} />
                                </div>
                                <span>Hair Cut</span>
                            </div>
                            <div className={styles.time}>30 min</div>
                            <div className={styles.price}>
                                <span>₹30.00</span>
                                <div className={styles.hoursArrow}>
                                    <img src={icons.hoursArrow} />
                                </div>
                            </div>
                        </div> 
                        <div className={styles.serviceList}>
                            <div className={styles.serviceName}>
                                <div className={styles.deleteIcon}>
                                    <img src={icons.deleteService} alt='' />
                                </div>
                                <span>Hair Cut</span>
                            </div>
                            <div className={styles.time}>30 min</div>
                            <div className={styles.price}>
                                <span>₹30.00</span>
                                <div className={styles.hoursArrow}>
                                    <img src={icons.hoursArrow} />
                                </div>
                            </div>
                        </div> 
                    </div>   
                    <div className={styles.addService}>+ Add Services</div>                                                   
                    <Button variant="contained" className={styles.btn} onClick={helpOne}>Continue</Button>
                </div> 
            </div> 
        </Grid>
    <Grid size={6} className={styles.companyBg}>
        <img src={icons.glam9Logo} alt='' />
    </Grid>        
</Grid>
  )
}

export default AddServices
