import React from 'react';
import Grid from '@mui/material/Grid2';
import { Box, Paper, Typography, TextField, Button, InputProps, Divider, IconButton, LinearProgress, InputAdornment, FormControl, InputLabel, Select, MenuItem, FormControlLabel,FormHelperText, Checkbox, Radio, RadioGroup, Switch, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions} from '@mui/material';
import { icons } from '../../Assets/icons'
import ProgressBar from './ProgressBar/ProgressBar';
import styles from "./Login.module.scss";
import { useNavigate } from 'react-router-dom';



const Team = () => {
  const navigate = useNavigate();
  const businessHours = () => {
    navigate('/business-hours'); // Navigate to the "About" page
  };
  return (
    <Grid container className={styles.loginBg}>
    <Grid size={6} className={styles.wrapContent}>            
        <div className={styles.formBox}>
            <div className={styles.backBtn}><img src={icons.backBtn} alt="" /></div>  
            <ProgressBar />   
            <div className={styles.logoMobile}><img src={icons.glam9Logo} alt="" /></div> 
            <div className={styles.formContent}>
                <div className={styles.titleBox}>
                    <h1>What’s Your Team Size?</h1>                    
                </div> 
                <div className={styles.radioWrap}>                
                <RadioGroup aria-labelledby="demo-radio-buttons-group-label" defaultValue="Just me" name="radio-buttons-group">
                    <FormControlLabel className="customRadio" value="Just me" control={<Radio classes={{
        root: styles.customRadio,
        checked: styles.checked,
      }} />} label="Just me" />
                    <FormControlLabel className="customRadio" value="2-3 Staff Members" control={<Radio classes={{
        root: styles.customRadio,
        checked: styles.checked,
      }} />} label="2-3 Staff Members" />
                    <FormControlLabel className="customRadio" value="4-6 Staff Members" control={<Radio classes={{
        root: styles.customRadio,
        checked: styles.checked,
      }} />} label="4-6 Staff Members" />
                    <FormControlLabel className="customRadio" value="More than 6 Staff Members" control={<Radio classes={{
        root: styles.customRadio,
        checked: styles.checked,
      }} />} label="More than 6 Staff Members" />
                </RadioGroup>          
                </div>                  
                <Button variant="contained" className={styles.btn} onClick={businessHours}>Continue</Button>
            </div> 
        </div> 
    </Grid>
    <Grid size={6} className={styles.companyBg}>
        <img src={icons.glam9Logo} alt='' />
    </Grid>        
</Grid>
  )
}

export default Team
