import React from 'react';
import Grid from '@mui/material/Grid2';
import { Box, Paper, Typography, TextField, Button, InputProps, Divider, IconButton, LinearProgress, InputAdornment, FormControl, InputLabel, Select, MenuItem, FormControlLabel,FormHelperText, Checkbox, Radio, RadioGroup, Switch, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions} from '@mui/material';
import { icons } from '../../Assets/icons'
import { SelectChangeEvent } from '@mui/material/Select';
import styles from "./Login.module.scss";
import ProgressBar from './ProgressBar/ProgressBar';
import { useNavigate } from 'react-router-dom';


const ConfirmYourAddress = () => {

    const [business, setBusiness] = React.useState('');

  const handleChange = (event: SelectChangeEvent) => {
    setBusiness(event.target.value as string);
  };  
  const navigate = useNavigate();
  const businessLocation = () => {
    navigate('/business-location');
  }
  return (
    <Grid container className={styles.loginBg}>
        <Grid size={6} className={styles.wrapContent}>            
            <div className={`${styles.formBox} ${styles.formBigBox}`}>
                <div className={styles.backBtn}><img src={icons.backBtn} alt="" /></div>  
                <ProgressBar />   
                <div className={styles.logoMobile}><img src={icons.glam9Logo} alt="" /></div> 
                <div className={`${styles.formContent} ${styles.formBigContent}`}>
                    <div className={styles.titleBox}>
                        <h1>Confirm Your Address</h1>
                        <p>Where can clients find you?</p>
                    </div>                    
                    <div className={styles.inputBox}>
                        <TextField placeholder="Street Address Like 1" variant="outlined" fullWidth className={styles.inputBx} />                        
                    </div>
                    <div className={styles.inputBox}>
                        <TextField placeholder="Street Address Like 2" variant="outlined" fullWidth className={styles.inputBx} />                        
                    </div>
                    
                    <div className={styles.selectBox}>
                        <FormControl fullWidth>                            
                        
                            <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            className="selectItem"
                            value={business}
                            // label="Select Business Type"                            
                            onChange={handleChange}                            
                            >
                            <MenuItem value={10}>Ten</MenuItem>
                            <MenuItem value={20}>Twenty</MenuItem>
                            <MenuItem value={30}>Thirty</MenuItem>
                            </Select>
                        </FormControl>                       
                    </div>
                    <div className={styles.selectBox}>
                        <FormControl fullWidth>                            
                        
                            <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            className="selectItem"
                            value={business}
                            // label="Select Business Type"                            
                            onChange={handleChange}                            
                            >
                            <MenuItem value={10}>Ten</MenuItem>
                            <MenuItem value={20}>Twenty</MenuItem>
                            <MenuItem value={30}>Thirty</MenuItem>
                            </Select>
                        </FormControl>                       
                    </div>
                    <div className={styles.wrapBtns}>
                        <Button variant="contained" className={`${styles.btn} ${styles.btnBorder}`}>Reset</Button>                    
                        <Button variant="contained" className={styles.btn} onClick={businessLocation}>Continue</Button>
                    </div> 
                </div> 
            </div> 
        </Grid>
        <Grid size={6} className={styles.companyBg}>
            <img src={icons.glam9Logo} alt='' />
        </Grid>        
    </Grid>
  )
}

export default ConfirmYourAddress



