import React, { useState } from 'react';
import Grid from '@mui/material/Grid2';
import { Box, Paper, Typography, TextField, Button, InputProps, Divider, IconButton, LinearProgress, InputAdornment, FormControl, InputLabel, Select, MenuItem, FormControlLabel,FormHelperText, Checkbox, Radio, RadioGroup, Switch, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions} from '@mui/material';
import { icons } from '../../Assets/icons'
import { Link } from 'react-router-dom';
import styles from "./Login.module.scss";
import ProgressBar from './ProgressBar/ProgressBar';
import { useNavigate } from 'react-router-dom';


const HelpTwo = () => {    
    const navigate = useNavigate();
    const helpThree = () => {
      navigate('/help-three'); 
    };
  return (
    <Grid container className={styles.loginBg}>
        <Grid size={6} className={styles.wrapContent}>            
            <div className={styles.formBox}>             
                <div className={styles.backBtn}><img src={icons.backBtn} alt="" /></div> 
                <ProgressBar/>   
                <div className={styles.logoMobile}><img src={icons.glam9Logo} alt="" /></div>                   
                <div className={styles.formContent}>
                    <div className={styles.titleBox}>
                        <h1 className={styles.textShort}>Have you previously used other tools to support your business?</h1>                        
                    </div>  
                            <div className={styles.radioWrap}>                
                        <RadioGroup aria-labelledby="demo-radio-buttons-group-label" defaultValue="Just me" name="radio-buttons-group">
                            <FormControlLabel className="customRadio" value="Just me" control={<Radio classes={{
                root: styles.customRadio,
                checked: styles.checked,
            }} />} label="Yes, I have" />
                            <FormControlLabel className="customRadio" value="2-3 Staff Members" control={<Radio classes={{
                root: styles.customRadio,
                checked: styles.checked,
            }} />} label="No, I haven’t" />
                            
                        </RadioGroup>          
                        </div> 
                    
                    <Button variant="contained" className={styles.btn} onClick={helpThree}>Continue</Button> 
                </div> 
            </div> 
        </Grid>
        <Grid size={6} className={styles.companyBg}>
            <img src={icons.glam9Logo} />
        </Grid>        
    </Grid>
  )
}

export default HelpTwo



