import React, { useState } from 'react';
import Grid from '@mui/material/Grid2';
import { Box, Paper, Typography, TextField, Button, InputProps, Divider, IconButton, LinearProgress, InputAdornment, FormControl, InputLabel, Select, MenuItem, FormControlLabel,FormHelperText, Checkbox, Radio, RadioGroup, Switch, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions} from '@mui/material';
import { icons } from '../../Assets/icons'
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { SelectChangeEvent } from '@mui/material/Select';
import styles from "./Login.module.scss";
import ProgressBar from './ProgressBar/ProgressBar';
import { useNavigate } from 'react-router-dom';

const AboutYou = () => {

    const [business, setBusiness] = React.useState('');

  const handleChange = (event: SelectChangeEvent) => {
    setBusiness(event.target.value as string);
  };
  const [isChecked, setIsChecked] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);
  const navigate = useNavigate();
  const whereDoYouWork = () => {
    navigate('/where-do-you-work'); // Navigate to the "About" page
  };

  return (
    <Grid container className={styles.loginBg}>
        <Grid size={6} className={styles.wrapContent}>            
            <div className={`${styles.formBox} ${styles.formBigBox}`}>
                <div className={styles.backBtn}><img src={icons.backBtn} alt="" /></div>  
                <ProgressBar />   
                <div className={styles.logoMobile}><img src={icons.glam9Logo} alt="" /></div> 
                <div className={`${styles.formContent} ${styles.formBigContent}`}>
                    <div className={styles.titleBox}>
                        <h1>About You</h1>
                        <p>Tell us more about yourself and your business</p>
                    </div>
                    <div className={styles.wrapInput}>
                        <div className={styles.inputBox}>
                            <TextField placeholder="Business Name" variant="outlined" fullWidth className={styles.inputBx} />                        
                        </div>
                        <div className={styles.inputBox}>
                            <TextField placeholder="Your Name" variant="outlined" fullWidth className={styles.inputBx} />                        
                        </div>
                    </div>
                    <div className={`${styles.selectBox} selectBox`}>
                        <FormControl fullWidth>                            
                        
                            <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            className="selectItem"
                            value={business}
                            // label="Select Business Type"                            
                            onChange={handleChange}                            
                            >
                            <MenuItem value={10}>Ten</MenuItem>
                            <MenuItem value={20}>Twenty</MenuItem>
                            <MenuItem value={30}>Thirty</MenuItem>
                            </Select>
                        </FormControl>                       
                    </div>
                    <div className={styles.inputBox}>
                        <TextField placeholder="Your Phone Number" variant="outlined" fullWidth className={`${styles.inputBx} ${styles.phoneNumber}`} />                        
                        <div className={styles.countryCode}>
                            <div className={styles.icon}><img src={icons.flagIndia} alt="" /></div>
                            <div className={styles.text}>+91</div>
                        </div>
                    </div>   
                    <div className={styles.checkBoxWrap}>
                    <FormControl className={styles.checkBoxField}>
                    <FormControlLabel
                    control={
                        <Checkbox
                        className={clsx(styles.customCheckbox, {
                          [styles.checked]: isChecked,
                        })}
                        checked={isChecked}
                        onChange={() => setIsChecked(!isChecked)}
                      />
                    }
                    label={
                      <Typography variant="body2">
                        I accept the{' '}
                        <a
                          href="/terms-of-services"                          
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Terms and Conditions
                        </a>{' '}
                        and confirm that I have read the{' '}
                        <a
                          href="/privacy-policy"                          
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Privacy Policy
                        </a>
                        .
                      </Typography>
                    }
                  />                  
                  
                </FormControl>
                <FormControl className={styles.checkBoxField}>
                  <FormControlLabel
                    control={
                        <Checkbox
                        className={clsx(styles.customCheckbox, {
                          [styles.checked]: isChecked2,
                        })}
                        checked={isChecked2}
                        onChange={() => setIsChecked2(!isChecked2)}
                      />
                    }
                    label={
                      <Typography variant="body2">
                        I want to receive information about Glam9 promotions and
                        services, including business tips.
                      </Typography>
                    }
                  />
                </FormControl>                                
                </div> 
                    <Button variant="contained" className={styles.btn} onClick={whereDoYouWork}>Continue</Button> 
                </div> 
            </div> 
        </Grid>
        <Grid size={6} className={styles.companyBg}>
            <img src={icons.glam9Logo} alt='' />
        </Grid>        
    </Grid>
  )
}

export default AboutYou


