import React, { useState } from 'react';
import Grid from '@mui/material/Grid2';
import { Box, Paper, Typography, TextField, Button, InputProps, Divider, IconButton, LinearProgress, InputAdornment, FormControl, InputLabel, Select, MenuItem, FormControlLabel,FormHelperText, Checkbox, Radio, RadioGroup, Switch, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions} from '@mui/material';
import { icons } from '../../Assets/icons'
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { SelectChangeEvent } from '@mui/material/Select';
import styles from "./Login.module.scss";
import ProgressBar from './ProgressBar/ProgressBar';
import { useNavigate } from 'react-router-dom';

const WhereDoYouWork = () => {

    const [business, setBusiness] = React.useState('');

  const handleChange = (event: SelectChangeEvent) => {
    setBusiness(event.target.value as string);
  };
  const [isChecked, setIsChecked] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);
  const [isChecked3, setIsChecked3] = useState(false);

  const navigate = useNavigate();
  const enterYourAddress = () => {
    navigate('/enter-your-address'); 
  };
  return (
    <Grid container className={styles.loginBg}>
        <Grid size={6} className={styles.wrapContent}>            
            <div className={styles.formBox}>                
                <div className={styles.backBtn}><img src={icons.backBtn} alt="" /></div> 
                <ProgressBar/>   
                <div className={styles.logoMobile}><img src={icons.glam9Logo} alt="" /></div>                   
                <div className={styles.formContent}>
                    <div className={styles.titleBox}>
                        <h1>Where do you work, Salon?</h1>
                        <p>Do your clients come to you, go to then, or both?</p>
                    </div>                          
                    <div className={styles.checkBoxWrap}>
                    <FormControl className={styles.checkBoxField}>
                    <FormControlLabel
                    control={
                        <Checkbox
                        className={clsx(styles.customCheckbox, {
                          [styles.checked]: isChecked,
                        })}
                        checked={isChecked}
                        onChange={() => setIsChecked(!isChecked)}
                      />
                    }
                    label={
                        <>
                            <div className={styles.textTitle}>At my place</div>
                            <p className={styles.text}>I accept the Terms and Conditions and confirm that I have
                            read the Privacy Policy</p>
                        </>
                    }
                  />                  
                  
                </FormControl>
                <FormControl className={styles.checkBoxField}>
                  <FormControlLabel
                    control={
                        <Checkbox
                        className={clsx(styles.customCheckbox, {
                          [styles.checked]: isChecked2,
                        })}
                        checked={isChecked2}
                        onChange={() => setIsChecked2(!isChecked2)}
                      />
                    }
                    label={
                        <>
                            <div className={styles.textTitle}>At the client’s location</div>
                            <p className="text">I’m on the go. My services are performed at the client’s location</p>
                        </>
                    }
                  />
                </FormControl>      
                <FormControl className={styles.checkBoxField}>
                  <FormControlLabel
                    control={
                        <Checkbox
                        className={clsx(styles.customCheckbox, {
                          [styles.checked]: isChecked3,
                        })}
                        checked={isChecked3}
                        onChange={() => setIsChecked3(!isChecked3)}
                      />
                    }
                    label={
                        <>
                            <div className={styles.textTitle}>Professional</div>
                            <p className="text">I’m on the go. My services are performed at the client’s location</p>
                        </>
                    }
                  />
                </FormControl>                                
                </div> 
                    <Button variant="contained" className={styles.btn} onClick={enterYourAddress}>Continue</Button> 
                </div> 
            </div> 
        </Grid>
        <Grid size={6} className={styles.companyBg}>
            <img src={icons.glam9Logo} />
        </Grid>        
    </Grid>
  )
}

export default WhereDoYouWork


