import React, { useState } from 'react';
import { Box, Paper, Typography, TextField, Button, InputProps, Divider, IconButton, LinearProgress, InputAdornment, FormControl, InputLabel, Select, MenuItem, FormControlLabel,FormHelperText, Checkbox, Radio, RadioGroup, Switch, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions} from '@mui/material';
import Grid from '@mui/material/Grid2';
import styles from "./Login.module.scss";
import { styled } from '@mui/material/styles';
import { icons } from '../../Assets/icons'
import { images } from '../../Assets/images'
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import SearchIcon from '@mui/icons-material/Search';
// Interface
import { vendorProfileData } from '../../../src/api/types/vendor';
import Flag from 'react-world-flags';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { red } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';


const Login: React.FC = () => {
  // States
  const [isNewProfile, setIsNewProfile] = useState(false)
  const [viewType, setViewType] = useState(1);
  const [vendorProfileData, setVendorProfileData] = useState<vendorProfileData>({
    email: "",
    password: ""
  })
  const [showPassword, setShowPassword] = useState(false);
  // Functions
  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const nextView = () => {
    let nextViewType = viewType + 1;
    
    setViewType(nextViewType)
    
  }
  const previousView = () => {
    let previousViewType = viewType - 1;
    setViewType(previousViewType)
  }

  const isValidEmail = (email: string) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  }
  

  const [loginEmail, setLoginEmail] = useState("");
  const [loginEmailErrror, setLoginEmailError] = useState("");

  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [hasLetter, setHasLetter] = useState(false);
  const [hasDigit, setHasDigit] = useState(false);
  const [isLongEnough, setIsLongEnough] = useState(false);

  const checkEmail = () => {
    setIsNewProfile(true)
      nextView()
    // API Call And Social API Call
 /*   let isValid = true;
    setLoginEmailError("");
    if(!isValidEmail(loginEmail)){
      setLoginEmailError("Invalid Email. Please enter proper email");
      isValid = false;
    }
    if(loginEmail == ''){
      setLoginEmailError("Please enter Your Email Id");
      isValid = false;
    }
    
    if(isValid == true){
      setIsNewProfile(true)
      nextView()
    } */
  }

  const handlePasswordChange = (event:any) => {
    const newPassword = event.target.value;
    setPassword(newPassword);

    // Check if the password meets the  criteria
    setHasLetter(newPassword.match(/[a-zA-Z]/));
    setHasDigit(newPassword.match(/[0-9]/));
    setIsLongEnough(newPassword.length >= 8);
  };

  const getTextColor = (condition:any) => {
    return condition ? 'green' : 'black';
  };

  const checkPassword = () => {
  /*  let isValid = true;
    setPasswordError("");
    if(!validatePassword(password)){
      setPasswordError("Pattern Not Matched");
      isValid = false;
    }
    if(password == ''){
      setPasswordError("Please enter Password");
      isValid = false;
    }
    if(isValid == true){
      setViewType(4)
    } */
      setViewType(4)
  }
  const [isChecked, setIsChecked] = useState(false);
  const handleChange = (event:any) => {
    setIsChecked(event.target.checked);
    if (event.target.checked) {
      setAboutTerms('1');
    }
  };

  const [aboutBusinessName, setAboutBusinessName] = useState("");
  const [aboutBusinessNameError, setAboutBusinessNameError] = useState("");

  const [aboutName, setAboutName] = useState("");
  const [aboutNameError, setAboutNameError] = useState("");

  const [aboutBusinessType, setAboutBusinessType] = useState("");
  const [aboutBusinessTypeError, setAboutBusinessTypeError] = useState("");

  const [aboutPhone, setAboutPhone] = useState("");
  const [aboutPhoneError, setaboutPhoneError] = useState("");

  const [aboutTerms, setAboutTerms] = useState("");
  const [aboutTermsError, setaboutTermsError] = useState("");

  const validateAboutUsData = () => {
  /*  let isValid = true;
    setAboutBusinessNameError("");
    setAboutNameError("")
    setAboutBusinessTypeError("");
    setaboutPhoneError("");
    setaboutTermsError("")
    if(aboutBusinessName == ''){
      setAboutBusinessNameError('please enter Your Business Name');
      isValid = false;
    }
    if(aboutName == ''){
      setAboutNameError('please enter Your Name');
      isValid = false;
    }
    if(aboutBusinessType == ''){
      setAboutBusinessTypeError('Please select Your Business Type');
      isValid = false;
    }
    

      if (aboutPhone == '') {
        setaboutPhoneError("Please enter Your Phone Number");
        isValid = false;
      } else if (aboutPhone.search(/[0-9]/) == -1) {
        setaboutPhoneError("Phone Number should be Numeric");
        isValid = false;
      } else if (aboutPhone.length < 10) {
        setaboutPhoneError("Phone Number must be 10 characters");
        isValid = false;
      }  
      
      if (!isChecked) {
        setaboutTermsError("Please Accept Terms and Contitions");
        isValid = false;
      }
      if(isValid == true){
        if(aboutBusinessType == '1'){
          setViewType(12);
        }else{
          setViewType(6);
        }
      } */
        setViewType(6);
  }

  const [myPlace, setmyPlace] = useState(true);
  const [Client, setClient] = useState(false);
  const [workError, setworkError] = useState("");

  const handleMyPlaceChange = (e:any) => {
    setmyPlace(e.target.checked);
    console.log("clicked");
  };
  const handleClientChange = (e:any) => {
    setClient(e.target.checked);
    console.log("clicked");
  };
  const validateWork = () => {
    setworkError("");
    let isValid = true;
    if(myPlace == false && Client == false){
      setworkError("Please check any one Options");
      isValid = false;
    }
    if(isValid == true){
      setViewType(7);
    }
  }
  

  const [streetAddress1, setStreetAddress1] = useState("");
  const [streetAddress1Error, setStreetAddress1Error] = useState("");

  const [streetAddress2, setStreetAddress2] = useState("");
  const [streetAddress2Error, setStreetAddress2Error] = useState("");

  const [country, setCountry] = useState("");
  const [countryError, setcountryError] = useState("");

  const [state, setState] = useState("");
  const [stateError, setStateError] = useState("");

  const [city, setCity] = useState("");
  const [cityError, setCityError] = useState("");

  const [postCode, setPostCode] = useState("");
  const [postCodeError, setPostCodeError] = useState("");

  const clearData = () => {
    setStreetAddress1("");
    setStreetAddress2("");
    setCountry("");
    setState("");
    setCity("");
    setPostCode("");
  };
  
  const validateAddress = () => {
  /*  let isValid = true;
    setStreetAddress1Error("");
    setStreetAddress2Error("");
    setcountryError("");
    setStateError("");
    setCityError("");
    setPostCodeError("");

    if(streetAddress1 === ''){
      setStreetAddress1Error('Please enter your street address 1');
      isValid = false;
    }
    if(streetAddress2 === ''){
      setStreetAddress2Error('Please enter your street address 2');
      isValid = false;
    }
    if(country === ''){
      setcountryError('Please select your Country');
      isValid = false;
    }
    if(state === ''){
      setStateError('Please select your state');
      isValid = false;
    }
    if(city === ''){
      setCityError('Please select your City');
      isValid = false;
    }
    if(postCode === ''){
      setPostCodeError('Please select your Postcode');
      isValid = false;
    }
    if(isValid === true){
      setViewType(10);
    } */
      setViewType(10);
  }

  
  
  const loginVendor = () => {
    console.log("Vendor LoggedIn");
  }
  
  const validatePassword = (password: string) => {
    const passwordPattern = /^(?=.*[a-zA-Z])(?=.*\d)(?=.{8,})/;
    return passwordPattern.test(password);
  }
  const onInputChange = (type: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (type === 1) {
      setVendorProfileData({ ...vendorProfileData, email: value })
    }
    if (type === 2) {
      setVendorProfileData({ ...vendorProfileData, password: value })
    }

  }
  // Check Boxes
  const icon = (
    <Box
      sx={{
        width: 30,
        height: 30,
        backgroundColor: 'white',
        borderRadius: '8px',
        border: '1px solid #231F20'
      }}
    />
  );
  const checkedIcon = (
    <Box
      sx={{
        width: 30,
        height: 30,
        backgroundColor: 'white',
        border: '1px solid #ccc',
        borderRadius: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#1976d2',
      }}
    >
      <img src={images.Checked} alt='Checked' style={{ width: 30, height: 30 }} />
    </Box>
  );
  // Dummy Data
  const premiumCategories = [
    {
      "id": 1,
      "name": "Nail Salon",
      "logo_link": "https://glam9.ybtest.co.in/uploads/categories/nail-salon-66dac6764ead5.png",
      "sequence": 2,
      "is_premium_category": 1
    },
    {
      "id": 7,
      "name": "Hair Salon",
      "logo_link": "https://glam9.ybtest.co.in/uploads/categories/hair-salon-66dac6764e81f.png",
      "sequence": 3,
      "is_premium_category": 1
    },
    {
      "id": 8,
      "name": "Brows & Lashes",
      "logo_link": "https://glam9.ybtest.co.in/uploads/categories/eye-brow-66dac6764ab3f.png",
      "sequence": 4,
      "is_premium_category": 1
    },
    {
      "id": 9,
      "name": "Skin Care",
      "logo_link": "https://glam9.ybtest.co.in/uploads/categories/skin-care-66dac6764ed8d.png",
      "sequence": 5,
      "is_premium_category": 1
    },
    {
      "id": 10,
      "name": "Aesthetic Medicine",
      "logo_link": "https://glam9.ybtest.co.in/uploads/categories/aesthetic-medicine-66dac6e25c004.png",
      "sequence": 6,
      "is_premium_category": 1
    },
    {
      "id": 11,
      "name": "Barber",
      "logo_link": "https://glam9.ybtest.co.in/uploads/categories/barber-66dac6764efd1.png",
      "sequence": 7,
      "is_premium_category": 1
    },
    {
      "id": 18,
      "name": "Braids & Locs",
      "logo_link": "https://glam9.ybtest.co.in/uploads/categories/default-66dac6c2b7b5e.png",
      "sequence": 8,
      "is_premium_category": 2
    },
    {
      "id": 19,
      "name": "Hair Removal",
      "logo_link": "https://glam9.ybtest.co.in/uploads/categories/default-66dac6c2b7b5e.png",
      "sequence": 9,
      "is_premium_category": 2
    },
    {
      "id": 20,
      "name": "Make up",
      "logo_link": "https://glam9.ybtest.co.in/uploads/categories/default-66dac6c2b7b5e.png",
      "sequence": 10,
      "is_premium_category": 1
    },
    {
      "id": 21,
      "name": "Massage",
      "logo_link": "https://glam9.ybtest.co.in/uploads/categories/default-66dac6c2b7b5e.png",
      "sequence": 11,
      "is_premium_category": 1
    },
    {
      "id": 22,
      "name": "Pet Services",
      "logo_link": "https://glam9.ybtest.co.in/uploads/categories/default-66dac6c2b7b5e.png",
      "sequence": 12,
      "is_premium_category": 1
    },
    {
      "id": 23,
      "name": "Hair Color",
      "logo_link": "https://glam9.ybtest.co.in/uploads/categories/default-66dac6c2b7b5e.png",
      "sequence": 17,
      "is_premium_category": 2
    },
    {
      "id": 24,
      "name": "Hair Cut",
      "logo_link": "https://glam9.ybtest.co.in/uploads/categories/default-66dac6c2b7b5e.png",
      "sequence": 16,
      "is_premium_category": 2
    }
  ]
  // Default Lat Long
  const latLong = {
    lat: 23.027181141518188,
    lng: 72.48185665364666,
  }
  const mapContainerStyle = {
    width: '100%',
    height: '300px',
  };
  const mapContainerStyleAddress = {
    width: '100%',
    height: '150px',
  };
  // Radio Button
  const [selectedValue, setSelectedValue] = React.useState('option1');

  const handleChangeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };
  // Dialog
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  // Service
  const [openService, setOpenService] = useState(false);

  const handleClickOpenService = () => {
    setOpenService(true);
  };

  const handleCloseService = () => {
    setOpenService(false);
  };
  // Logs
  console.log(viewType, "viewType");
  console.log(isNewProfile, "isNewProfile");

  const navigate = useNavigate();
  const Password = () => {
    navigate('/password'); 
  };


  return (
//     <Grid container height="100vh">
//       <Grid
//         item xs={12} sm={6}
//         sx={{
//           height: '100%',
//           display: 'flex',
//           alignItems: 'center',
//           justifyContent: 'center',
//         }}
//         className="loginFormBackground"
//       >
//         {viewType === 1 && (
//           <Paper
//             elevation={3}
//             className="loginFormBox"
//             sx={{
//               width: '100%',
//               height: '100%',
//               display: 'flex',
//               alignItems: 'center',
//               justifyContent: 'center',
//               flexDirection: 'column',
//             }}
//           >
//             <Typography className="loginTitle">Login</Typography>
//             <Typography className="loginSubTitle">Enter your Email to login</Typography>
//             {/*   onChange={(event: React.ChangeEvent<HTMLInputElement>) => { onInputChange(1, event) }} */}
//             <TextField
//               placeholder="Email Address"
//               variant="outlined"
//               sx={{ mt: 3, width: '80%' }}
//               error={!!loginEmailErrror} // Boolean for error prop
//               helperText={loginEmailErrror} 
//               onChange={(e) => setLoginEmail(e.target.value)}
//             />
//             <Button
//               variant="contained"
//               color="primary"
//               sx={{
//                 mt: 3,
//                 width: '80%',
//                 padding: '10px',
//                 fontSize: '16px',
//                 textTransform: "capitalize",
//                 fontWeight: "300",
//               }}
//               onClick={() => { checkEmail() }}
              
//             >
//               Continue With Email
//             </Button>
//             <Divider sx={{ width: '80%', mt: 3, mb: 3 }}><Typography variant="body1">OR</Typography></Divider>
//             <Button
//               variant="outlined"
//               className="loginSocialButtons"
//             >
//               <img
//                 src={images.Google}
//                 alt="Google logo"
//                 style={{ width: 24, height: 24, marginRight: 8 }}
//               />
//               Continue with Google
//             </Button>
//             <Button
//               variant="outlined"
//               className="loginSocialButtons"
//             >
//               <img
//                 src={images.Facebook}
//                 alt="Google logo"
//                 style={{ width: 24, height: 24, marginRight: 8 }}
//               />
//               Continue with Facebook
//             </Button>
//             {/* <Button
//               variant="outlined"
//               className="loginSocialButtons"
//             >
//               <img
//                 src={images.Apple}
//                 alt="Google logo"
//                 style={{ width: 24, height: 24, marginRight: 8 }}
//               />
//               Continue with Apple
//             </Button> */}
//           </Paper>
//         )}
//         {viewType === 2 && !isNewProfile && (
//           <Paper
//             elevation={3}
//             className="loginFormBox"
//             sx={{
//               width: '100%',
//               height: '100%',
//               display: 'flex',
//               alignItems: 'flex-start',
//               justifyContent: 'flex-start',
//               position: 'relative',
//               flexDirection: 'column',
//               p: 2,
//             }}
//           >
//             <Box
//               sx={{
//                 width: '100%',
//                 display: 'flex',
//                 alignItems: 'center',
//                 position: 'relative',
//                 mt: 2,
//               }}
//             >
//               <IconButton
//                 sx={{ ml: 2 }}
//                 color="primary"
//                 onClick={() => { setViewType(1) }}
//               >
//                 <img
//                   src={images.leftArrow}
//                   alt="Back arrow"
//                   style={{ width: 40, height: 40 }}
//                 />
//               </IconButton>
//               <Box sx={{ flex: 1, ml: 4 }}>
//                 <LinearProgress
//                   variant="determinate"
//                   value={3}
//                   sx={{
//                     height: 10,
//                     width: "80%",
//                     backgroundColor: '#EEEEEE',
//                     '& .MuiLinearProgress-bar': {
//                       backgroundColor: '#FAB93B',
//                     },
//                   }}
//                 />
//               </Box>
//             </Box>
//             <Box
//               sx={{
//                 width: '70%',
//                 display: 'flex',
//                 alignItems: 'center',
//                 position: 'relative',
//                 justifyContent: 'center',
//                 flexDirection: 'column',
//                 margin: "0 auto",
//                 marginTop: "20px"
//               }}
//             >
//               <Typography className="passwordTitle">Password</Typography>
//               <Typography className="passwordSubTitle">It looks like there's already a profile
//                 associated with this email address</Typography>
//               <TextField
//                 placeholder="Password"
//                 variant="outlined"
//                 sx={{ mt: 3, width: '80%' }}
//                 type={showPassword ? 'text' : 'password'}
//                 onChange={(event: React.ChangeEvent<HTMLInputElement>) => { onInputChange(2, event); }}
//                 InputProps={{
//                   endAdornment: (
//                     <InputAdornment position="end">
//                       <IconButton
//                         onClick={handleTogglePasswordVisibility}
//                         edge="end"
//                         aria-label="toggle password visibility"
//                       >
//                         {showPassword ? <VisibilityOff /> : <Visibility />}
//                       </IconButton>
//                     </InputAdornment>
//                   ),
//                 }}
//               />
//               <Typography className="forgotPasswordLink" onClick={() => { setViewType(3) }}>Forgot Password?</Typography>
//               <Button
//                 variant="contained"
//                 color="primary"
//                 sx={{
//                   mt: 8,
//                   width: '80%',
//                   padding: '10px',
//                   fontSize: '16px',
//                 }}
//                 onClick={() => { loginVendor() }}
//                 disabled={!vendorProfileData.password ? true : false}
//               >
//                 Continue
//               </Button>
//               <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', marginTop: '20px', marginLeft: "20px" }}>
//                 <span className='terms'>By signing up I agree to the</span>
//                 <span className='termsLink'>
//                   Terms & Conditions
//                 </span>
//                 <span className='terms'>and to the</span>
//                 <span className='termsLink'>
//                   Privacy Policy
//                 </span>
//               </Typography>
//             </Box>
//           </Paper>
//         )}
//         {viewType === 3 && (
//           <Paper
//             elevation={3}
//             className="loginFormBox"
//             sx={{
//               width: '100%',
//               height: '100%',
//               display: 'flex',
//               alignItems: 'flex-start',
//               justifyContent: 'flex-start',
//               position: 'relative',
//               flexDirection: 'column',
//               p: 2,
//             }}
//           >
//             <Box
//               sx={{
//                 width: '100%',
//                 display: 'flex',
//                 alignItems: 'center',
//                 position: 'relative',
//                 mt: 2,
//               }}
//             >
//               <IconButton
//                 sx={{ ml: 2 }}
//                 color="primary"
//                 onClick={() => { setViewType(1) }}
//               >
//                 <img
//                   src={images.leftArrow}
//                   alt="Back arrow"
//                   style={{ width: 24, height: 24 }}
//                 />
//               </IconButton>
//               <Box sx={{ flex: 1, ml: 4 }}>
//                 <LinearProgress
//                   variant="determinate"
//                   value={3}
//                   sx={{
//                     height: 10,
//                     width: "80%",
//                     backgroundColor: '#EEEEEE',
//                     '& .MuiLinearProgress-bar': {
//                       backgroundColor: '#FAB93B',
//                     },
//                   }}
//                 />
//               </Box>
//             </Box>
//             <Box
//               sx={{
//                 width: '70%',
//                 display: 'flex',
//                 alignItems: 'center',
//                 position: 'relative',
//                 justifyContent: 'center',
//                 flexDirection: 'column',
//                 margin: "0 auto",
//                 marginTop: "20px"
//               }}
//             >
//               <Typography className="passwordTitle">Forgot Password?</Typography>
//               <Typography className="passwordSubTitle">Enter the email associated with your Glem9 account. </Typography>
//               <TextField
//                 placeholder="Email Address"
//                 variant="outlined"
//                 sx={{ mt: 3, width: '80%' }}
//                 onChange={(event: React.ChangeEvent<HTMLInputElement>) => { onInputChange(1, event) }}
//               />
//               <Button
//                 variant="contained"
//                 color="primary"
//                 sx={{
//                   mt: 3,
//                   width: '80%',
//                   padding: '10px',
//                   fontSize: '16px',
//                 }}
//                 onClick={() => { checkEmail() }}
//                 disabled={!isValidEmail(vendorProfileData.email) ? true : false}
//               >
//                 Reset Password
//               </Button>
//             </Box>
//           </Paper>
//         )}
//         {viewType === 2 && isNewProfile && (
//           <Paper
//             elevation={3}
//             className="loginFormBox password"
//             sx={{
//               width: '100%',
//               height: '100%',
//               display: 'flex',
//               alignItems: 'flex-start',
//               justifyContent: 'flex-start',
//               position: 'relative',
//               flexDirection: 'column',
//               p: 2,
//             }}
//           >
//             <Box
//               sx={{
//                 width: '100%',
//                 display: 'flex',
//                 alignItems: 'center',
//                 position: 'relative',
//                 mt: 2,
//               }}
//             >
//               <IconButton
//                 sx={{ ml: 2 }}
//                 color="primary"
//                 onClick={() => { setViewType(1) }}
//               >
//                 <img
//                   src={images.leftArrow}
//                   alt="Back arrow"
//                   style={{ width: 40, height: 40 }}
//                 />
//               </IconButton>
//               <Box sx={{ flex: 1, ml: 4 }}>
//                 <LinearProgress
//                   variant="determinate"
//                   value={20}
//                   sx={{
//                     height: 10,
//                     width: "80%",
//                     backgroundColor: '#EEEEEE',
//                     '& .MuiLinearProgress-bar': {
//                       backgroundColor: '#FAB93B',
//                     },
//                   }}
//                 />
//               </Box>
//             </Box>
//             <Box
//               sx={{
//                 width: '100%',
//                 display: 'flex',
//                 alignItems: 'center',
//                 position: 'relative',
//                 justifyContent: 'center',
//                 flexDirection: 'column',
//                 margin: "0 auto",
//                 marginTop: "20px"
//               }}
//             >
//               <Typography className="passwordTitle">Password</Typography>
//               <Typography className="passwordSubTitle">Enter the password for your business profile</Typography>
//               {/* onChange={(event: React.ChangeEvent<HTMLInputElement>) => { onInputChange(2, event); }} 
//               onChange={(e) => setPassword(e.target.value)}
//               */}
//               <TextField
//                 placeholder="Password"
//                 variant="outlined"
//                 sx={{ mt: 3, width: { xl : '60%', md: '80%' }}}
//                 type={showPassword ? 'text' : 'password'}
//                 error={!!passwordError} // Boolean for error prop
//                 helperText={passwordError} 
//                 onChange={handlePasswordChange}
                
//                 InputProps={{
//                   endAdornment: (
//                     <InputAdornment position="end">
//                       <IconButton
//                         onClick={handleTogglePasswordVisibility}
//                         edge="end"
//                         aria-label="toggle password visibility"
//                       >
//                         {showPassword ? <VisibilityOff /> : <Visibility />}
//                       </IconButton>
//                     </InputAdornment>
//                   ),
//                 }}
//               />
//               <Box sx={{ marginTop: "20px", width: { xl : '60%', md: '80%' } }}>
//                 <Typography className="" sx={{fontSize: "20px"}}>The password must contain</Typography>
//                 <br />
//                 <Typography className="passwordNote"  style={{ color: getTextColor(hasLetter) }}>
//                   <img
//                     src={images.check}
//                     alt="Check Icon"
//                     style={{ width: 12, height: 12 }}
//                   /> at least one letter
//                 </Typography>
//                 <Typography className="passwordNote" style={{ color: getTextColor(hasDigit) }}>
//                   <img
//                     src={images.check}
//                     alt="Check Icon"
//                     style={{ width: 12, height: 12 }}
//                   /> at least one digit
//                 </Typography>
//                 <Typography className="passwordNote" style={{ color: getTextColor(isLongEnough) }}>
//                   <img
//                     src={images.check}
//                     alt="Check Icon"
//                     style={{ width: 12, height: 12 }}
//                   /> 8 characters or more
//                 </Typography>
//               </Box>
//               <Button
//                 variant="contained"
//                 color="primary"
//                 sx={{
//                   mt: 4,
//                   width: { xl : '60%', md: '80%' },
//                   padding: '10px',
//                   mb: 2,
//                   fontSize: '16px',
//                   textTransform: 'capitalize'
//                 }}
//                 onClick={() => { checkPassword() }}
//                /* onClick={() => { setViewType(4) }}
//                 disabled={!validatePassword(vendorProfileData.password) ? true : false} */
//               >
//                 Continue
//               </Button>
//             </Box>
//           </Paper>
//         )}
//         {viewType === 4 && isNewProfile && (
//           <Paper
//             elevation={3}
//             className="loginFormBox business"
//             sx={{
//               width: '100%',
//               height: '100%',
//               display: 'flex',
//               alignItems: 'flex-start',
//               justifyContent: 'flex-start',
//               position: 'relative',
//               flexDirection: 'column',
//               p: 2,
//             }}
//           >
//             <Box
//               sx={{
//                 width: '100%',
//                 display: 'flex',
//                 alignItems: 'center',
//                 position: 'relative',
//                 mt: 2,
//               }}
//             >
//               <IconButton
//                 sx={{ ml: 2 }}
//                 color="primary"
//                 onClick={() => { setViewType(2) }}
//               >
//                 <img
//                   src={images.leftArrow}
//                   alt="Back arrow"
//                   style={{ width: 40, height: 40 }}
//                 />
//               </IconButton>
//               <Box sx={{ flex: 1, ml: 4 }}>
//                 <LinearProgress
//                   variant="determinate"
//                   value={20}
//                   sx={{
//                     height: 10,
//                     width: "80%",
//                     backgroundColor: '#EEEEEE',
//                     '& .MuiLinearProgress-bar': {
//                       backgroundColor: '#FAB93B',
//                     },
//                   }}
//                 />
//               </Box>
//             </Box>
//             <Box
//               className="business_row"
//               sx={{
//                 width: '70%',
//                 display: 'flex',
//                 alignItems: 'center',
//                 position: 'relative',
//                 justifyContent: 'center',
//                 flexDirection: 'column',
//                 margin: "0 auto",
//                 marginTop: "20px"
//               }}
//             >
//               <Typography className="passwordTitle">What’s your business?</Typography>
//               <Typography className="passwordSubTitle">Select the category you feel best represents your business</Typography>
//               <Grid container sx={{ marginTop: "20px" }}>
//                 {premiumCategories.map((row, index) => {
//                   if (row.is_premium_category === 1) {
//                     return (
//                       <Grid
//                         item
//                         xs={6}
//                         sm={4}
//                         key={index} // Add a unique key for each item
//                         style={{ textAlign: 'center', marginBottom: "20px", cursor: 'pointer' }}
                        
//                       >
//                         <img
//                           src={row.logo_link}
//                           alt="Category logo" // Update alt text for better accessibility
//                           style={{
//                             width: 30,
//                             height: 30,
//                             padding: 5,
//                             borderRadius: "50%",
//                             border: "1px #FAB93B solid"
//                           }}
//                         />
//                         <Typography className="categoryTitleImage">{row.name}</Typography>
//                       </Grid>
//                     );
//                   }
//                   return null; // Return null if the condition is not met
//                 })}
//               </Grid>
//               <Grid container sx={{ marginTop: "10px" }}>
//                 <Grid
//                   item xs={12} sm={12} style={{ textAlign: 'center', marginBottom: "20px" }}
//                 >
//                   <Typography className="otherCategories">Other Categories</Typography>
//                 </Grid>
//                 {premiumCategories.map((row, index) => {
//                   if (row.is_premium_category === 2) {
//                     return (
//                       <Grid
//                         item
//                         xs={6}
//                         sm={3}
//                         key={index}
//                         style={{ textAlign: 'center', marginBottom: "20px" }}
//                         className='otherCategoriesTitleDiv'
//                       >
//                         <Typography className="otherCategoriesTitle">{row.name}</Typography>
//                       </Grid>
//                     );
//                   }
//                   return null;
//                 })}
//               </Grid>
//               <Button
//                 variant="contained"
//                 color="primary"
//                 sx={{
//                   mt: 1,
//                   width: '80%',
//                   padding: '10px',
//                   fontSize: '16px',
//                   textTransform: 'capitalize',
//                 }}
//                 onClick={() => setViewType(5)}
//                /* disabled={!validatePassword(vendorProfileData.password) ? true : false} */
//               >
//                 Continue
//               </Button>
//             </Box>
//           </Paper>
//         )}
//         {viewType === 5 && isNewProfile && (
//           <Paper
//             elevation={3}
//             className="loginFormBox about"
//             sx={{
//               width: '100%',
//               height: '100%',
//               display: 'flex',
//               alignItems: 'flex-start',
//               justifyContent: 'flex-start',
//               position: 'relative',
//               flexDirection: 'column',
//               p: 2,
//             }}
//           >
//             <Box
//               sx={{
//                 width: '100%',
//                 display: 'flex',
//                 alignItems: 'center',
//                 position: 'relative',
//                 mt: 2,
//               }}
//             >
//               <IconButton
//                 sx={{ ml: 2 }}
//                 color="primary"
//                 onClick={() => { setViewType(4) }}
//               >
//                 <img
//                   src={images.leftArrow}
//                   alt="Back arrow"
//                   style={{ width: 40, height: 40 }}
//                 />
//               </IconButton>
//               <Box sx={{ flex: 1, ml: 4 }}>
//                 <LinearProgress
//                   variant="determinate"
//                   value={40}
//                   sx={{
//                     height: 10,
//                     width: "80%",
//                     backgroundColor: '#EEEEEE',
//                     '& .MuiLinearProgress-bar': {
//                       backgroundColor: '#FAB93B',
//                     },
//                   }}
//                 />
//               </Box>
//             </Box>
//             <Box
//               sx={{
//                 width: '70%',
//                 display: 'flex',
//                 alignItems: 'center',
//                 position: 'relative',
//                 justifyContent: 'center',
//                 flexDirection: 'column',
//                 margin: "0 auto",
//                 marginTop: "20px"
//               }}
//             >
//               <Typography className="passwordTitle">About You</Typography>
//               <Typography className="passwordSubTitle">Tell us more about yourself and your business</Typography>
//               <Grid container spacing={2} style={{ marginTop: "20px" }}>
//                 {/* First Row: Two Half-Width Text Boxes */}
//                 <Grid item xs={12} sm={6}>
//                   <TextField
//                     className='inputFormAbout'
//                     fullWidth
//                     label="Business Name"
//                     variant="outlined"
//                     required
//                     error={!!aboutBusinessNameError} // Boolean for error prop
//                     helperText={aboutBusinessNameError} 
//                     onChange={(e) => setAboutBusinessName(e.target.value)}
//                   />
//                 </Grid>
//                 <Grid item xs={12} sm={6}>
//                   <TextField
//                     fullWidth
//                     label="Your Name"
//                     variant="outlined"
//                     required
//                     error={!!aboutNameError} // Boolean for error prop
//                     helperText={aboutNameError} 
//                     onChange={(e) => setAboutName(e.target.value)}
//                   />
//                 </Grid>

//                 {/* Second Row: Full Width Text Box */}
//                 <Grid item xs={12}>
//                   <FormControl fullWidth variant="outlined" error={!!aboutBusinessTypeError}>
//                     <InputLabel id="dropdown-label">Select Business Type</InputLabel>
//                     <Select
//                       labelId="dropdown-label"
//                       label="Select Option"
//                       required
//                       onChange={e => {
//                         setAboutBusinessType(e.target.value as string);
//                       }} // Cast event value to string
//                     >
//                       <MenuItem value={1}>Professional</MenuItem>
//                       <MenuItem value={2}>Saloon</MenuItem>
//                     </Select>
//                     {aboutBusinessTypeError && <FormHelperText>{aboutBusinessTypeError}</FormHelperText>}
//                   </FormControl>
//                 </Grid>
//                 <Grid item xs={12}>
//                   {/* <FormControl fullWidth variant="outlined">
//                     <InputLabel id="country-select-label">Country</InputLabel>
//                     <Select
//                       labelId="country-select-label"
//                       label="Country"
//                       required
//                       defaultValue="IN"
//                       sx={{ marginBottom: '10px' }}
//                     >
//                       <MenuItem value="IN">India</MenuItem>
//                       <MenuItem value="US">USA</MenuItem>
//                       <MenuItem value="UK">UK</MenuItem>
//                     </Select>
//                   </FormControl> */}
//                   <TextField
//                     fullWidth
//                     label="Phone Number"
//                     variant="outlined"
//                     required
//                     error={!!aboutPhoneError} // Boolean for error prop
//                     helperText={aboutPhoneError} 
//                     onChange={(e) => setAboutPhone(e.target.value)}
//                     InputProps={{
//                       startAdornment: (
//                         <InputAdornment position="start">
//                           <Flag code="IN" style={{ width: '24px', height: '24px', marginRight: '8px' }} />
//                         </InputAdornment>
//                       ),
//                     }}
//                     placeholder="+91 "
//                   />
//                 </Grid>
//                 <Grid item xs={12}>
//                 <FormControl error={!!aboutTermsError}>
//   <FormControlLabel
//     onChange={handleChange}
//     control={
//       <Checkbox name="" />
//     }
//     label="I accept the Terms and Conditions and confirm that I have read the Privacy Policy"
//   />
//   {aboutTermsError && <FormHelperText>{aboutTermsError}</FormHelperText>}
// </FormControl>
//                 </Grid>
//                 <Grid item xs={12}>
//                   <FormControlLabel
//                     control={
//                       <Checkbox
//                        /*  checked={true} */
//                         onChange={() => { console.log("clicked"); }}
//                         name="newsletters"
//                         /* icon={icon}
//                          checkedIcon={icon} */
//                       />
//                     }
//                     label="I want to receive information about Glam9 promotions and services, including business tips."
//                   />
//                 </Grid>
//                 <Button
//                   variant="contained"
//                   color="primary"
//                   sx={{
//                     mt: 4,
//                     width: '80%',
//                     padding: '10px',
//                     fontSize: '16px',
//                     marginLeft: "50px",
//                     textTransform: 'capitalize'
//                   }}
//                   /* onClick={() => { setViewType(6) }} */
//                   onClick={() => { validateAboutUsData() }}
//                 >
//                   Continue
//                 </Button>
//               </Grid>
//             </Box>
//           </Paper>
//         )}
//         {viewType === 6 && isNewProfile && (
//           <Paper
//             elevation={3}
//             className="loginFormBox work"
//             sx={{
//               width: '100%',
//               height: '100%',
//               display: 'flex',
//               alignItems: 'flex-start',
//               justifyContent: 'flex-start',
//               position: 'relative',
//               flexDirection: 'column',
//               p: 2,
//             }}
//           >
//             <Box
//               sx={{
//                 width: '100%',
//                 display: 'flex',
//                 alignItems: 'center',
//                 position: 'relative',
//                 mt: 2,
//               }}
//             >
//               <IconButton
//                 sx={{ ml: 2 }}
//                 color="primary"
//                 onClick={() => { setViewType(5) }}
//               >
//                 <img
//                   src={images.leftArrow}
//                   alt="Back arrow"
//                   style={{ width: 40, height: 40 }}
//                 />
//               </IconButton>
//               <Box sx={{ flex: 1, ml: 4 }}>
//                 <LinearProgress
//                   variant="determinate"
//                   value={45}
//                   sx={{
//                     height: 10,
//                     width: "80%",
//                     backgroundColor: '#EEEEEE',
//                     '& .MuiLinearProgress-bar': {
//                       backgroundColor: '#FAB93B',
//                     },
//                   }}
//                 />
//               </Box>
//             </Box>
//             <Box
//               sx={{
//                 width: '70%',
//                 display: 'flex',
//                 alignItems: 'center',
//                 position: 'relative',
//                 justifyContent: 'center',
//                 flexDirection: 'column',
//                 margin: "0 auto",
//                 marginTop: "20px"
//               }}
//             >
//               <Typography className="passwordTitle"> Where do you work, Salon?</Typography>
//               <Typography className="passwordSubTitle">Do your clients come to you, do you go to them, or both?</Typography>
//               <Grid container spacing={2} style={{ marginTop: "20px" }}>
//                 <Grid item xs={12}>
//                   <FormControlLabel
//                     control={
//                       <Checkbox
//                         checked={myPlace}
//                         className='myPlace'
//                         onChange={handleMyPlaceChange}
//                         name="newsletters"
//                         icon={icon}
//                         checkedIcon={checkedIcon}
//                       />
//                     }
//                     label={
//                       <Box>
//                         <Typography className='checkBoxLabel' variant="body1">
//                           At my place
//                         </Typography>
//                         <Typography className='checkBoxSubLabel' variant="body2" color="text.secondary">
//                         I accept the Terms and Conditions and confirm that I have read the Privacy Policy
//                         </Typography>
//                       </Box>
//                     }
//                   />


//                 </Grid>
//                 <Grid item xs={12}>
                  

// <FormControlLabel
//                     control={
//                       <Checkbox
//                         checked={Client}
//                         onChange={ handleClientChange }
//                         name="newsletters"
//                          icon={icon}
//                          checkedIcon={checkedIcon} 
//                       />
//                     }
//                     label={
//                       <Box>
//                         <Typography className='checkBoxLabel' variant="body1">
//                           At the client's location
//                         </Typography>
//                         <Typography className='checkBoxSubLabel' variant="body2" color="text.secondary">
//                           I'm on the go. My services are performed at the client's location.
//                         </Typography>
//                       </Box>
//                     }
//                   />
//                 </Grid>
//                 <Typography className='checkBoxLabel' sx={{color: "red", fontSize: "16px" }} variant="body1">{workError}</Typography>
//                 <Button
//                   variant="contained"
//                   color="primary"
//                   sx={{
//                     mt: 4,
//                     width: '80%',
//                     padding: '10px',
//                     fontSize: '16px',
//                     marginLeft: "50px",
//                     textTransform: 'capitalize'
//                   }}
//                   onClick={validateWork}
//                 >
//                   Continue
//                 </Button>
//               </Grid>
//             </Box>
//           </Paper>
//         )}
//         {viewType === 7 && isNewProfile && (
//           <Paper
//             elevation={3}
//             className="loginFormBox address"
//             sx={{
//               width: '100%',
//               height: '100%',
//               display: 'flex',
//               alignItems: 'flex-start',
//               justifyContent: 'flex-start',
//               position: 'relative',
//               flexDirection: 'column',
//               p: 2,
//             }}
//           >
//             <Box
//               sx={{
//                 width: '100%',
//                 display: 'flex',
//                 alignItems: 'center',
//                 position: 'relative',
//                 mt: 2,
//               }}
//             >
//               <IconButton
//                 sx={{ ml: 2 }}
//                 color="primary"
//                 onClick={() => { setViewType(6) }}
//               >
//                 <img
//                   src={images.leftArrow}
//                   alt="Back arrow"
//                   style={{ width: 40, height: 40 }}
//                 />
//               </IconButton>
//               <Box sx={{ flex: 1, ml: 4 }}>
//                 <LinearProgress
//                   variant="determinate"
//                   value={50}
//                   sx={{
//                     height: 10,
//                     width: "80%",
//                     backgroundColor: '#EEEEEE',
//                     '& .MuiLinearProgress-bar': {
//                       backgroundColor: '#FAB93B',
//                     },
//                   }}
//                 />
//               </Box>
//             </Box>
//             <Box
//               sx={{
//                 width: '70%',
//                 display: 'flex',
//                 alignItems: 'center',
//                 position: 'relative',
//                 justifyContent: 'center',
//                 flexDirection: 'column',
//                 margin: "0 auto",
//                 marginTop: "20px"
//               }}
//             >
//               <Typography className="passwordTitle"> Your Address</Typography>
//               <Typography className="passwordSubTitle">Where can clients find you?</Typography>
//               <Grid container spacing={2} style={{ marginTop: "20px" }}>
//                 <Grid item xs={12}>
//                   {latLong && process.env.REACT_APP_GOOGLE_MAPS_API_KEY && (
//                     <LoadScript
//                       googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
//                     >
//                       <GoogleMap
//                         mapContainerStyle={mapContainerStyle}
//                         zoom={10}
//                         center={latLong}
//                       >
//                         <Marker position={latLong} />
//                       </GoogleMap>
//                     </LoadScript>
//                   )}
//                 </Grid>
//                 <Button
//                   variant="contained"
//                   color="primary"
//                   sx={{
//                     mt: 4,
//                     width: '80%',
//                     padding: '10px',
//                     fontSize: '16px',
//                     textTransform: "capitalize",
//                     marginLeft: "50px"

//                   }}
//                   onClick={() => { setViewType(8) }}
//                 >
//                   Continue
//                 </Button>
//               </Grid>
//             </Box>
//           </Paper>
//         )}
//         {viewType === 8 && isNewProfile && (
//           <Paper
//             elevation={3}
//             className="loginFormBox search"
//             sx={{
//               width: '100%',
//               height: '100%',
//               display: 'flex',
//               alignItems: 'flex-start',
//               justifyContent: 'flex-start',
//               position: 'relative',
//               flexDirection: 'column',
//               p: 2,
//             }}
//           >
//             <Box
//               sx={{
//                 width: '100%',
//                 display: 'flex',
//                 alignItems: 'center',
//                 position: 'relative',
//                 mt: 2,
//               }}
//             >
//               <IconButton
//                 sx={{ ml: 2 }}
//                 color="primary"
//                 onClick={() => { setViewType(7) }}
//               >
//                 <img
//                   src={images.leftArrow}
//                   alt="Back arrow"
//                   style={{ width: 40, height: 40 }}
//                 />
//               </IconButton>
//               <Box sx={{ flex: 1, ml: 4 }}>
//                 <LinearProgress
//                   variant="determinate"
//                   value={60}
//                   sx={{
//                     height: 10,
//                     width: "80%",
//                     backgroundColor: '#EEEEEE',
//                     '& .MuiLinearProgress-bar': {
//                       backgroundColor: '#FAB93B',
//                     },
//                   }}
//                 />
//               </Box>
//             </Box>
//             <Box
//               sx={{
//                 width: '70%',
//                 display: 'flex',
//                 alignItems: 'center',
//                 position: 'relative',
//                 justifyContent: 'center',
//                 flexDirection: 'column',
//                 margin: "0 auto",
//                 marginTop: "20px"
//               }}
//             >
//               <Typography className="passwordTitle">  Enter Your Address</Typography>
//               <Typography className="passwordSubTitle">Where can clients find you?</Typography>
//               <Grid container spacing={2} style={{ marginTop: "20px", textAlign: 'center' }}>
//                 <Grid item xs={12}>
//                   <TextField
//                     placeholder="Search"
//                     variant="outlined"
//                     sx={{ mt: 3, width: '80%' }}
//                     onChange={(event: React.ChangeEvent<HTMLInputElement>) => { onInputChange(2, event); }}
//                     InputProps={{
//                       endAdornment: (
//                         <InputAdornment position="end">
//                           <IconButton
//                             onClick={handleTogglePasswordVisibility}
//                             edge="end"
//                             aria-label="toggle password visibility"
//                           >
//                             {<SearchIcon />}
//                           </IconButton>
//                         </InputAdornment>
//                       ),
//                     }}
//                   />
//                 </Grid>
//                 <Grid item xs={12} style={{ height: "200px" }}>

//                 </Grid>
//                 <Grid item xs={12}>
//                   <Grid container >
//                     <Grid item xs={6}>
//                       <Typography className="searchDesc">Can't find your address?</Typography>
//                     </Grid>
//                     <Grid item xs={6}>
//                       <Button
//                         variant="contained"
//                         color="primary"
//                         sx={{
//                           mt: 4,
//                           width: '80%',
//                           padding: '10px',
//                           fontSize: '16px',
//                           marginLeft: "50px",
//                           marginTop: "-10px",
//                           textTransform: 'capitalize'
//                         }}
//                         onClick={() => { setViewType(9) }}
//                       >
//                         Continue
//                       </Button>
//                     </Grid>
//                   </Grid>
//                 </Grid>
//               </Grid>
//             </Box>
//           </Paper>
//         )}
//         {viewType === 9 && isNewProfile && (
//           <Paper
//             elevation={3}
//             className="loginFormBox confirm"
//             sx={{
//               width: '100%',
//               height: '100%',
//               display: 'flex',
//               alignItems: 'flex-start',
//               justifyContent: 'flex-start',
//               position: 'relative',
//               flexDirection: 'column',
//               p: 2,
//             }}
//           >
//             <Box
//               sx={{
//                 width: '100%',
//                 display: 'flex',
//                 alignItems: 'center',
//                 position: 'relative',
//                 mt: 2,
//               }}
//             >
//               <IconButton
//                 sx={{ ml: 2 }}
//                 color="primary"
//                 onClick={() => { setViewType(8) }}
//               >
//                 <img
//                   src={images.leftArrow}
//                   alt="Back arrow"
//                   style={{ width: 40, height: 40 }}
//                 />
//               </IconButton>
//               <Box sx={{ flex: 1, ml: 4 }}>
//                 <LinearProgress
//                   variant="determinate"
//                   value={60}
//                   sx={{
//                     height: 10,
//                     width: "80%",
//                     backgroundColor: '#EEEEEE',
//                     '& .MuiLinearProgress-bar': {
//                       backgroundColor: '#FAB93B',
//                     },
//                   }}
//                 />
//               </Box>
//             </Box>
//             <Box
//               sx={{
//                 width: '70%',
//                 display: 'flex',
//                 alignItems: 'center',
//                 position: 'relative',
//                 justifyContent: 'center',
//                 flexDirection: 'column',
//                 margin: "0 auto",
//                 marginTop: "20px"
//               }}
//             >
//               <Typography className="passwordTitle">Confirm Your Address</Typography>
//               <Typography className="passwordSubTitle">Where can clients find you?</Typography>
//               <Grid container spacing={2} style={{ marginTop: "20px" }}>
//                 {/* First Row: Two Half-Width Text Boxes */}
//                 <Grid item xs={12} sm={12}>
//                   <TextField
//                     className='inputFormAbout'
//                     fullWidth
//                     label="Street Address Line 1"
//                     variant="outlined"
//                     required
//                     error={!!streetAddress1Error} // Boolean for error prop
//                     helperText={streetAddress1Error} 
//                     onChange={(e) => setStreetAddress1(e.target.value)}
//                   />
//                 </Grid>
//                 <Grid item xs={12} sm={12}>
//                   <TextField
//                     className='inputFormAbout'
//                     fullWidth
//                     label="Street Address Line 2"
//                     variant="outlined"
//                     required
//                     error={!!streetAddress2Error} // Boolean for error prop
//                     helperText={streetAddress2Error} 
//                     onChange={(e) => setStreetAddress2(e.target.value)}
//                   />
//                 </Grid>

                


//                   <Grid item xs={12}>
//                   <FormControl fullWidth variant="outlined" error={!!countryError}>
//                     <InputLabel id="dropdown-label">Country</InputLabel>
//                     <Select
//                       labelId="dropdown-label"
//                       label="Select Option"
//                       required
//                       onChange={e => {
//                         setCountry(e.target.value as string);
//                       }}
//                     >
//                       <MenuItem value={1}>Option 1</MenuItem>
//                       <MenuItem value={2}>Option 2</MenuItem>
//                       <MenuItem value={3}>Option 3</MenuItem>
//                     </Select>
//                     {countryError && <FormHelperText>{countryError}</FormHelperText>}
//                   </FormControl>
//                 </Grid>

//                 <Grid item xs={12}>
//                   <FormControl fullWidth variant="outlined" error={!!stateError}>
//                     <InputLabel id="dropdown-label">State</InputLabel>
//                     <Select
//                       labelId="dropdown-label"
//                       label="Select Option"
//                       required
//                       onChange={e => {
//                         setState(e.target.value as string);
//                       }}
//                     >
//                       <MenuItem value={1}>Option 1</MenuItem>
//                       <MenuItem value={2}>Option 2</MenuItem>
//                       <MenuItem value={3}>Option 3</MenuItem>
//                     </Select>
//                     {stateError && <FormHelperText>{stateError}</FormHelperText>}
//                   </FormControl>
//                 </Grid>

//                 <Grid item xs={12}>
//                   <FormControl fullWidth variant="outlined" error={!!cityError}>
//                     <InputLabel id="dropdown-label">City</InputLabel>
//                     <Select
//                       labelId="dropdown-label"
//                       label="Select Option"
//                       required
//                       onChange={e => {
//                         setCity(e.target.value as string);
//                       }}
//                     >
//                       <MenuItem value={1}>Option 1</MenuItem>
//                       <MenuItem value={2}>Option 2</MenuItem>
//                       <MenuItem value={3}>Option 3</MenuItem>
//                     </Select>
//                     {cityError && <FormHelperText>{cityError}</FormHelperText>}
//                   </FormControl>
//                 </Grid>
//                 <Grid item xs={12} sm={12}>
//                   <TextField
//                     className='inputFormAbout'
//                     fullWidth
//                     label="Passcode"
//                     variant="outlined"
//                     required
//                     error={!!postCodeError} // Boolean for error prop
//                     helperText={postCodeError} 
//                     onChange={(e) => setPostCode(e.target.value)}
//                   />
//                 </Grid>
//                 <Grid item xs={6}>
//                    <Button
//                     variant="contained"
//                     className='buttonNo'
//                     sx={{
//                       mt: 4,
//                       width: '99%',
//                       padding: '10px',
//                       fontSize: '16px',
//                       marginLeft: "0px",
//                       background: "white",
//                       color: "#0178FA",
//                       boxShadow: "none",
//                       border: '1px solid #0178FA',
//                       textTransform: 'capitalize'
//                     }}
//                     onClick={ clearData }
//                   >
//                     Reset
//                   </Button> 
//                 </Grid>
//                 <Grid item xs={6}>
//                   <Button
//                     variant="contained"
//                     color="primary"
//                     sx={{
//                       mt: 4,
//                       width: '99%',
//                       padding: '10px',
//                       fontSize: '16px',
//                       marginLeft: "0px",
//                       textTransform: 'capitalize'
//                     }}
//                     onClick={ validateAddress }
//                   >
//                     Continue
//                   </Button>
//                 </Grid>

//               </Grid>
//             </Box>
//           </Paper>
//         )}
//         {viewType === 10 && isNewProfile && (
//           <Paper
//             elevation={3}
//             className="loginFormBox workHere"
//             sx={{
//               width: '100%',
//               height: '100%',
//               display: 'flex',
//               alignItems: 'flex-start',
//               justifyContent: 'flex-start',
//               position: 'relative',
//               flexDirection: 'column',
//               p: 2,
//             }}
//           >
//             <Box
//               sx={{
//                 width: '100%',
//                 display: 'flex',
//                 alignItems: 'center',
//                 position: 'relative',
//                 mt: 2,
//               }}
//             >
//               <IconButton
//                 sx={{ ml: 2 }}
//                 color="primary"
//                 onClick={() => { setViewType(9) }}
//               >
//                 <img
//                   src={images.leftArrow}
//                   alt="Back arrow"
//                   style={{ width: 40, height: 40 }}
//                 />
//               </IconButton>
//               <Box sx={{ flex: 1, ml: 4 }}>
//                 <LinearProgress
//                   variant="determinate"
//                   value={65}
//                   sx={{
//                     height: 10,
//                     width: "80%",
//                     backgroundColor: '#EEEEEE',
//                     '& .MuiLinearProgress-bar': {
//                       backgroundColor: '#FAB93B',
//                     },
//                   }}
//                 />
//               </Box>
//             </Box>
//             <Box
//               sx={{
//                 width: '70%',
//                 display: 'flex',
//                 alignItems: 'center',
//                 position: 'relative',
//                 justifyContent: 'center',
//                 flexDirection: 'column',
//                 margin: "0 auto",
//                 marginTop: "20px"
//               }}
//             >
//               <Typography className="passwordTitle"> Do You Work Here?</Typography>
//               <Typography className="passwordSubTitle">If so we'll link your Galm9 profile to this Shared Location.
//                 Clients will be able to find you and book you through
//                 either profile page.</Typography>
//               <Grid container spacing={2} style={{ marginTop: "20px", }}>
//                 <Grid item xs={12}>
//                   {latLong && process.env.REACT_APP_GOOGLE_MAPS_API_KEY && (
//                     <LoadScript
//                       googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
//                     >
//                       <GoogleMap
//                         mapContainerStyle={mapContainerStyleAddress}
//                         zoom={10}
//                         center={latLong}
//                         mapContainerClassName='googleMap'
//                       >
//                         <Marker position={latLong} />
//                       </GoogleMap>
//                     </LoadScript>
//                   )}
//                 </Grid>
//                 <Grid item xs={12} sm={12}>
//                   <TextField
//                     className='inputFormAbout'
//                     fullWidth
//                     label="Suit Number"
//                     variant="outlined"
//                     required
//                   />
//                 </Grid>
//                 <Grid item xs={6}>
//                   <Button
//                     variant="contained"
//                     sx={{
//                       mt: 4,
//                       width: '99%',
//                       padding: '10px',
//                       fontSize: '16px',
//                       marginLeft: "0px",
//                       textTransform: 'capitalize'
//                     }}
//                     onClick={() => { setViewType(11) }}
//                   >
//                     Yes
//                   </Button>
//                 </Grid>
//                 <Grid item xs={6}>
//                   <Button
//                     variant="contained"
//                     color="primary"
//                     className="buttonNo"
//                     sx={{
//                       mt: 4,
//                       width: '99%',
//                       padding: '10px',
//                       fontSize: '16px',
//                       marginLeft: "0px",
//                       backgroundColor: "white",
//                       color: "#0178FA",
//                       border: "1px solid #0178FA",
//                       boxShadow: "none",
//                       textTransform: 'capitalize'
//                     }}
//                     onClick={() => { setViewType(11) }}
//                   >
//                     No
//                   </Button>
//                 </Grid>
//               </Grid>
//             </Box>
//           </Paper>
//         )}
//         {viewType === 11 && isNewProfile && (
//           <Paper
//             elevation={3}
//             className="loginFormBox teamsize"
//             sx={{
//               width: '100%',
//               height: '100%',
//               display: 'flex',
//               alignItems: 'flex-start',
//               justifyContent: 'flex-start',
//               position: 'relative',
//               flexDirection: 'column',
//               p: 2,
//             }}
//           >
//             <Box
//               sx={{
//                 width: '100%',
//                 display: 'flex',
//                 alignItems: 'center',
//                 position: 'relative',
//                 mt: 2,
//               }}
//             >
//               <IconButton
//                 sx={{ ml: 2 }}
//                 color="primary"
//                 onClick={() => { setViewType(10) }}
//               >
//                 <img
//                   src={images.leftArrow}
//                   alt="Back arrow"
//                   style={{ width: 40, height: 40 }}
//                 />
//               </IconButton>
//               <Box sx={{ flex: 1, ml: 4 }}>
//                 <LinearProgress
//                   variant="determinate"
//                   value={65}
//                   sx={{
//                     height: 10,
//                     width: "80%",
//                     backgroundColor: '#EEEEEE',
//                     '& .MuiLinearProgress-bar': {
//                       backgroundColor: '#FAB93B',
//                     },
//                   }}
//                 />
//               </Box>
//             </Box>
//             <Box
//               sx={{
//                 width: '70%',
//                 display: 'flex',
//                 alignItems: 'center',
//                 position: 'relative',
//                 justifyContent: 'center',
//                 flexDirection: 'column',
//                 margin: "0 auto",
//                 marginTop: "20px"
//               }}
//             >
//               <Typography className="passwordTitle"> What’s your team size?</Typography>
//               <Grid container spacing={2} style={{ marginTop: "20px", textAlign: 'center' }}>
//                 <Grid item xs={12} sm={12} style={{ marginLeft: "50px" }}>
//                   <RadioGroup
//                     aria-labelledby="radio-buttons-group-label"
//                     name="options"
//                     value={selectedValue}
//                     onChange={handleChangeRadio}
//                   >
//                     <FormControlLabel
//                       value="option1"
//                       control={<Radio />}
//                       label="Just me"
//                     />
//                     <FormControlLabel
//                       value="option2"
//                       control={<Radio />}
//                       label="2-3 staff members"
//                     />
//                     <FormControlLabel
//                       value="option3"
//                       control={<Radio />}
//                       label="4-6 staff members"
//                     />
//                     <FormControlLabel
//                       value="option4"
//                       control={<Radio />}
//                       label="More than 6 staff members"
//                     />
//                   </RadioGroup>
//                 </Grid>
//                 <Grid item xs={12}>
//                   <Button
//                     variant="contained"
//                     color="primary"
//                     sx={{
//                       mt: 4,
//                       width: '80%',
//                       padding: '10px',
//                       fontSize: '16px',
//                       marginLeft: "0px",
//                       textTransform: 'capitalize'
//                     }}
//                     onClick={() => { setViewType(12) }}
//                   >
//                     Continue
//                   </Button>
//                 </Grid>
//               </Grid>
//             </Box>
//           </Paper>
//         )}
//         {viewType === 12 && isNewProfile && (
//           <Paper
//             elevation={3}
//             className="loginFormBox businessHours"
//             sx={{
//               width: '100%',
//               height: '100%',
//               display: 'flex',
//               alignItems: 'flex-start',
//               justifyContent: 'flex-start',
//               position: 'relative',
//               flexDirection: 'column',
//               p: 2,
//             }}
//           >
//             <Box
//               sx={{
//                 width: '100%',
//                 display: 'flex',
//                 alignItems: 'center',
//                 position: 'relative',
//                 mt: 2,
//               }}
//             >
//               <IconButton
//                 sx={{ ml: 2 }}
//                 color="primary"
//                 onClick={() => { setViewType(11) }}
//               >
//                 <img
//                   src={images.leftArrow}
//                   alt="Back arrow"
//                   style={{ width: 40, height: 40 }}
//                 />
//               </IconButton>
//               <Box sx={{ flex: 1, ml: 4 }}>
//                 <LinearProgress
//                   variant="determinate"
//                   value={65}
//                   sx={{
//                     height: 10,
//                     width: "80%",
//                     backgroundColor: '#EEEEEE',
//                     '& .MuiLinearProgress-bar': {
//                       backgroundColor: '#FAB93B',
//                     },
//                   }}
//                 />
//               </Box>
//             </Box>
//             <Box
//               sx={{
//                 width: '70%',
//                 display: 'flex',
//                 alignItems: 'center',
//                 position: 'relative',
//                 justifyContent: 'center',
//                 flexDirection: 'column',
//                 margin: "0 auto",
//                 marginTop: "20px"
//               }}
//             >
//               <Typography className="passwordTitle"> Your Business Hours</Typography>
//               <Typography className="passwordSubTitle">Where can clients find you?</Typography>
//               <Grid container spacing={2} style={{ marginTop: "20px", textAlign: 'center' }}>
//                 {[...Array(7)].map((_, index) => (
//                   <Grid item xs={12} sm={12} style={{ marginLeft: "50px" }}>
//                     <Grid container>
//                       <Grid item xs={1}>
//                         <FormControlLabel
//                           style={{ marginTop: "-5px" }}
//                           control={
//                             <Switch
//                               checked={true}
//                               onChange={() => { console.log("Switch Clicked"); }}
//                               name="exampleSwitch"
//                               color="primary"
//                             />
//                           }
//                           label=""
//                         />
//                       </Grid>
//                       <Grid item xs={3}>
//                         <Typography>Monday</Typography>
//                       </Grid>
//                       <Grid item xs={1} />
//                       <Grid item xs={3}>
//                         <Typography>10:00 - 12:00</Typography>
//                       </Grid>
//                       <Grid item xs={1} />
//                       <Grid item xs={1}>
//                         <ArrowForwardIosIcon fontSize="small" onClick={handleClickOpen} />
//                       </Grid>
//                     </Grid>
//                   </Grid>
//                 ))}
//                 <Grid item xs={12}>
//                   <Button
//                     variant="contained"
//                     color="primary"
//                     sx={{
//                       mt: 4,
//                       width: '80%',
//                       padding: '10px',
//                       fontSize: '16px',
//                       marginLeft: "0px",
//                       marginTop: "5px",
//                       textTransform: "capitalize",
//                     }}
//                     onClick={() => { setViewType(13) }}
//                   >
//                     Continue
//                   </Button>
//                 </Grid>
//               </Grid>
//             </Box>
//           </Paper>
//         )}
//         {viewType === 13 && isNewProfile && (
//           <Paper
//             elevation={3}
//             className="loginFormBox service"
//             sx={{
//               width: '100%',
//               height: '100%',
//               display: 'flex',
//               alignItems: 'flex-start',
//               justifyContent: 'flex-start',
//               position: 'relative',
//               flexDirection: 'column',
//               p: 2,
//             }}
//           >
//             <Box
//               sx={{
//                 width: '100%',
//                 display: 'flex',
//                 alignItems: 'center',
//                 position: 'relative',
//                 mt: 2,
//               }}
//             >
//               <IconButton
//                 sx={{ ml: 2 }}
//                 color="primary"
//                 onClick={() => { setViewType(12) }}
//               >
//                 <img
//                   src={images.leftArrow}
//                   alt="Back arrow"
//                   style={{ width: 40, height: 40 }}
//                 />
//               </IconButton>
//               <Box sx={{ flex: 1, ml: 4 }}>
//                 <LinearProgress
//                   variant="determinate"
//                   value={65}
//                   sx={{
//                     height: 10,
//                     width: "80%",
//                     backgroundColor: '#EEEEEE',
//                     '& .MuiLinearProgress-bar': {
//                       backgroundColor: '#FAB93B',
//                     },
//                   }}
//                 />
//               </Box>
//             </Box>
//             <Box
//               sx={{
//                 width: '70%',
//                 display: 'flex',
//                 alignItems: 'center',
//                 position: 'relative',
//                 justifyContent: 'center',
//                 flexDirection: 'column',
//                 margin: "0 auto",
//                 marginTop: "20px"
//               }}
//             >
//               <Typography className="passwordTitle"> Start Adding Services</Typography>
//               <Typography className="passwordSubTitle">Add at least one service now. Later you can add more,
//                 edit details, and group services into categories.</Typography>
//               <Grid container spacing={2} style={{ marginTop: "20px", textAlign: 'center' }}>
//                 {[...Array(4)].map((_, index) => (
//                   <Grid item xs={12} sm={12} style={{ marginLeft: "50px", marginTop: "10px" }}>
//                     <Grid container>
//                       <Grid item xs={1}>
//                         <img src={images.Cancel} alt='cancel' style={{ height: 15, width: 15 }} />
//                       </Grid>
//                       <Grid item xs={3}>
//                         <Typography className='serviceTextLabel'>Hair Cut</Typography>
//                       </Grid>
//                       <Grid item xs={1} />
//                       <Grid item xs={2}>
//                         <Typography className='serviceTextDuration'>30 min</Typography>
//                       </Grid>
//                       <Grid item xs={3}>
//                         <Typography className='serviceTextCurrency'>₹30.00</Typography>
//                       </Grid>
//                       <Grid item xs={1} />
//                       <Grid item xs={1}>
//                         <ArrowForwardIosIcon fontSize="small" onClick={handleClickOpenService} />
//                       </Grid>
//                     </Grid>
//                   </Grid>
//                 ))}
//                 <Grid item xs={12} style={{ marginTop: "30px", marginBottom: "20px", marginLeft: "50px", textAlign: "left" }}>
//                   <Typography className='serviceTextAction'>+ Add Service</Typography>
//                 </Grid>
//                 <Grid item xs={12}>
//                   <Button
//                     variant="contained"
//                     color="primary"
//                     sx={{
//                       mt: 4,
//                       width: '80%',
//                       padding: '10px',
//                       fontSize: '16px',
//                       marginLeft: "0px",
//                       marginTop: "-5px"
//                     }}
//                     onClick={() => { setViewType(14) }}
//                   >
//                     Continue
//                   </Button>
//                 </Grid>
//               </Grid>
//             </Box>
//           </Paper>
//         )}
//         {viewType === 14 && isNewProfile && (
//           <Paper
//             elevation={3}
//             className="loginFormBox"
//             sx={{
//               width: '100%',
//               height: '100%',
//               display: 'flex',
//               alignItems: 'flex-start',
//               justifyContent: 'flex-start',
//               position: 'relative',
//               flexDirection: 'column',
//               p: 2,
//             }}
//           >
//             <Box
//               sx={{
//                 width: '100%',
//                 display: 'flex',
//                 alignItems: 'center',
//                 position: 'relative',
//                 mt: 2,
//               }}
//             >
//               <IconButton
//                 sx={{ ml: 2 }}
//                 color="primary"
//                 onClick={() => { setViewType(1) }}
//               >
//                 <img
//                   src={images.leftArrow}
//                   alt="Back arrow"
//                   style={{ width: 24, height: 24 }}
//                 />
//               </IconButton>
//               <Box sx={{ flex: 1, ml: 4 }}>
//                 <LinearProgress
//                   variant="determinate"
//                   value={80}
//                   sx={{
//                     height: 10,
//                     width: "80%",
//                     backgroundColor: '#EEEEEE',
//                     '& .MuiLinearProgress-bar': {
//                       backgroundColor: '#FAB93B',
//                     },
//                   }}
//                 />
//               </Box>
//             </Box>
//             <Box
//               sx={{
//                 width: '70%',
//                 display: 'flex',
//                 alignItems: 'center',
//                 position: 'relative',
//                 justifyContent: 'center',
//                 flexDirection: 'column',
//                 margin: "0 auto",
//                 marginTop: "20px"
//               }}
//             >
//               <Typography className="passwordTitle">  How do you hope Glam9 can help you?</Typography>

//               <Grid container spacing={2} style={{ marginTop: "20px", textAlign: 'center' }}>
//                 <Grid item xs={12} sm={12} style={{ marginLeft: "50px", marginTop: "20px", marginBottom: "20px", textAlign: "left" }}>
//                   <Typography className="glamHelp"> Choose Upto 5</Typography>
//                 </Grid>
//                 {[...Array(10)].map((_, index) => (
//                   <Grid className='glamHelpLabelBox' item xs={6} sm={4} style={{ marginLeft: "50px", marginTop: "10px" }}>
//                     <Typography className="glamHelpBoxContent"> Attract New Clients</Typography>
//                   </Grid>
//                 ))}
//                 <Grid item xs={12} style={{ marginTop: "20px" }}>
//                   <Button
//                     variant="contained"
//                     color="primary"
//                     sx={{
//                       mt: 4,
//                       width: '80%',
//                       padding: '10px',
//                       fontSize: '16px',
//                       marginLeft: "0px",
//                       marginTop: "-5px"
//                     }}
//                     onClick={() => { setViewType(15) }}
//                   >
//                     Continue
//                   </Button>
//                 </Grid>
//               </Grid>
//             </Box>
//           </Paper>
//         )}
//         {viewType === 15 && isNewProfile && (
//           <Paper
//             elevation={3}
//             className="loginFormBox"
//             sx={{
//               width: '100%',
//               height: '100%',
//               display: 'flex',
//               alignItems: 'flex-start',
//               justifyContent: 'flex-start',
//               position: 'relative',
//               flexDirection: 'column',
//               p: 2,
//             }}
//           >
//             <Box
//               sx={{
//                 width: '100%',
//                 display: 'flex',
//                 alignItems: 'center',
//                 position: 'relative',
//                 mt: 2,
//               }}
//             >
//               <IconButton
//                 sx={{ ml: 2 }}
//                 color="primary"
//                 onClick={() => { setViewType(1) }}
//               >
//                 <img
//                   src={images.leftArrow}
//                   alt="Back arrow"
//                   style={{ width: 24, height: 24 }}
//                 />
//               </IconButton>
//               <Box sx={{ flex: 1, ml: 4 }}>
//                 <LinearProgress
//                   variant="determinate"
//                   value={95}
//                   sx={{
//                     height: 10,
//                     width: "80%",
//                     backgroundColor: '#EEEEEE',
//                     '& .MuiLinearProgress-bar': {
//                       backgroundColor: '#FAB93B',
//                     },
//                   }}
//                 />
//               </Box>
//             </Box>
//             <Box
//               sx={{
//                 width: '70%',
//                 display: 'flex',
//                 alignItems: 'center',
//                 position: 'relative',
//                 justifyContent: 'center',
//                 flexDirection: 'column',
//                 margin: "0 auto",
//                 marginTop: "20px"
//               }}
//             >
//               <Typography className="passwordTitle">  Have you previously used
//                 other tools to support
//                 your business?</Typography>
//               <Grid container spacing={2} style={{ marginTop: "20px", textAlign: 'center' }}>
//                 <Grid item xs={12} sm={12} style={{ marginLeft: "50px" }}>
//                   <RadioGroup
//                     aria-labelledby="radio-buttons-group-label"
//                     name="options"
//                     value={selectedValue}
//                     onChange={handleChangeRadio}
//                   >
//                     <FormControlLabel
//                       value="option1"
//                       control={<Radio />}
//                       label="Yes, I have"
//                     />
//                     <FormControlLabel
//                       value="option2"
//                       control={<Radio />}
//                       label="No, I haven’t"
//                     />
//                   </RadioGroup>
//                 </Grid>
//                 <Grid item xs={12}>
//                   <Button
//                     variant="contained"
//                     color="primary"
//                     sx={{
//                       mt: 4,
//                       width: '80%',
//                       padding: '10px',
//                       fontSize: '16px',
//                       marginLeft: "0px",
//                     }}
//                     onClick={() => { setViewType(16) }}
//                   >
//                     Continue
//                   </Button>
//                 </Grid>
//               </Grid>
//             </Box>
//           </Paper>
//         )}
//         {viewType === 16 && isNewProfile && (
//           <Paper
//             elevation={3}
//             className="loginFormBox"
//             sx={{
//               width: '100%',
//               height: '100%',
//               display: 'flex',
//               alignItems: 'flex-start',
//               justifyContent: 'flex-start',
//               position: 'relative',
//               flexDirection: 'column',
//               p: 2,
//             }}
//           >
//             <Box
//               sx={{
//                 width: '70%',
//                 display: 'flex',
//                 alignItems: 'center',
//                 position: 'relative',
//                 justifyContent: 'center',
//                 flexDirection: 'column',
//                 margin: "0 auto",
//                 marginTop: "20px"
//               }}
//             >
//               <Typography className="passwordTitle">You're All Set</Typography>
//               <Typography className="passwordSubTitle">Welcome to the best tool for managing
//                 your Business</Typography>
//               <Grid container spacing={2} style={{ marginTop: "20px", textAlign: 'center' }}>
//                 <Grid item xs={12} sm={12} style={{ marginLeft: "50px" }}>
//                   <Typography className="passwordSubTitle">Glam9 Biz Pro is designed to help you keep peace. Manage team calendars, create Shifts, process payments, run reports, and check stock levels - all from the front desk.

//                     And, when you're on the go? Use Glam9 Biz on your mobile devices to access core features and keep tabs on your business from anywhere.</Typography>
//                 </Grid>
//                 <Grid item xs={12}>
//                   <Button
//                     variant="contained"
//                     color="primary"
//                     sx={{
//                       mt: 4,
//                       width: '80%',
//                       padding: '10px',
//                       fontSize: '16px',
//                       marginLeft: "0px",
//                     }}
//                     onClick={() => { setViewType(17) }}
//                   >
//                     Continue
//                   </Button>
//                 </Grid>
//               </Grid>
//             </Box>
//           </Paper>
//         )}
//       </Grid>
//       <Grid
//         className='logo'
//         item xs={12} sm={6}
//         sx={{
//           height: '100%',
//           display: 'flex',
//           alignItems: 'center',
//           justifyContent: 'center',
//         }}
//       >
//         <Box
//           component="img"
//           src={images.LogoBg}
//           alt="Login Logo"
//           sx={{
//             width: '100%',
//             height: '100%',
//             objectFit: 'cover',
//             backgroundColor: 'black',
//           }}
//         />
//         <Box
//           component="img"
//           src={images.Logo}
//           alt="Login Logo"
//           sx={{
//             objectFit: 'cover',
//             position:'absolute',
//             width: {
//               xs: '11px',
//               sm: '25%',
//               md: '25%',
//               lg: '25%',
//               xl: '25%',
//             },
//           }}
          
//         />
//       </Grid>
//       {/* Time Add */}
//       <Dialog open={open} onClose={handleClose} style={{ borderRadius: "100px" }}>
//         <DialogContent>
//           <DialogContentText>
//             <Typography className="passwordTitle"> Monday</Typography>
//             <Typography className="passwordSubTitle"> Set your business hours here. Head to your calendar
//               if you need to adjust hours for a single day.</Typography>
//           </DialogContentText>
//           <Grid container spacing={2} style={{ marginTop: "20px", textAlign: 'center' }}>
//             <Grid item xs={12} sm={12} style={{ marginLeft: "50px" }}>
//               <Grid container>
//                 <Grid item xs={3}>
//                   <Typography className='popupTimeText'>Opening Hours</Typography>
//                 </Grid>
//                 <Grid item xs={1} />
//                 <Grid item xs={3}>
//                   <FormControl fullWidth variant="outlined">
//                     <Select
//                       labelId="dropdown-label"
//                       label="10:00"
//                       required
//                       value={"10:00"}
//                     >
//                       <MenuItem value={"10:00"}>10:00</MenuItem>
//                     </Select>
//                   </FormControl>
//                 </Grid>
//                 <Grid item xs={1} />
//                 <Grid item xs={3}>
//                   <FormControl fullWidth variant="outlined">
//                     <Select
//                       labelId="dropdown-label"
//                       label="10:00"
//                       value={"10:00"}
//                       required
//                     >
//                       <MenuItem value={"10:00"}>10:00</MenuItem>
//                     </Select>
//                   </FormControl>
//                 </Grid>
//               </Grid>
//             </Grid>
//             <Grid item xs={12} sm={12} style={{ marginLeft: "50px" }}>
//               <Grid container>
//                 <Grid item xs={3}>
//                   <Typography className='popupTimeText'>Closed</Typography>
//                 </Grid>
//                 <Grid item xs={1} />
//                 <Grid item xs={3}>
//                   <FormControl fullWidth variant="outlined">
//                     <Select
//                       labelId="dropdown-label"
//                       label="10:00"
//                       required
//                       value={"10:00"}
//                     >
//                       <MenuItem value={"10:00"}>10:00</MenuItem>
//                     </Select>
//                   </FormControl>
//                 </Grid>
//                 <Grid item xs={1} />
//                 <Grid item xs={3}>
//                   <FormControl fullWidth variant="outlined">
//                     <Select
//                       labelId="dropdown-label"
//                       label="10:00"
//                       value={"10:00"}
//                       required
//                     >
//                       <MenuItem value={"10:00"}>10:00</MenuItem>
//                     </Select>
//                   </FormControl>
//                 </Grid>
//               </Grid>
//             </Grid>
//             <Grid item xs={12} sm={12} style={{ marginLeft: "50px" }}>
//               <Grid container>
//                 <Grid item xs={3}>
//                   <Typography className='popupTimeTextAction'>+ Add Break</Typography>
//                 </Grid>
//               </Grid>
//             </Grid>
//           </Grid>
//         </DialogContent>
//         <DialogActions>
//           <Grid container spacing={2} style={{ marginBottom: "20px", textAlign: 'center' }}><Grid item xs={6}>
//             <Button
//               variant="contained"
//               sx={{
//                 mt: 4,
//                 width: '80%',
//                 padding: '10px',
//                 fontSize: '16px',
//                 marginLeft: "20px",
//                 marginTop: "10px"
//               }}
//               onClick={() => { setViewType(13) }}
//             >
//               Cancel
//             </Button>
//           </Grid>
//             <Grid item xs={6}>
//               <Button
//                 variant="contained"
//                 color="primary"
//                 sx={{
//                   mt: 4,
//                   width: '80%',
//                   padding: '10px',
//                   fontSize: '16px',
//                   marginLeft: "20px",
//                   marginTop: "10px"
//                 }}
//                 onClick={() => { setViewType(13) }}
//               >
//                 Save
//               </Button>
//             </Grid>
//           </Grid>
//         </DialogActions>
//       </Dialog>
//       {/* Service Add */}
//       <Dialog open={openService} onClose={handleCloseService} style={{ borderRadius: "100px" }}>
//         <DialogContent>
//           <DialogContentText>
//             <Typography className="passwordTitle">  Service Details</Typography>
//             <Typography className="passwordSubTitle"> Add the basic information for this service now.
//               You'll be able to add a description and adjust
//               the advanced settings for this service later on.</Typography>
//           </DialogContentText>
//           <Grid container spacing={2} style={{ marginTop: "20px" }}>
//             {/* First Row: Two Half-Width Text Boxes */}
//             <Grid item xs={12} sm={12}>
//               <TextField
//                 className='inputFormAbout'
//                 fullWidth
//                 label="Service Name"
//                 variant="outlined"
//                 required
//               />
//             </Grid>
//             <Grid item xs={12}>
//               <FormControl fullWidth variant="outlined">
//                 <InputLabel id="dropdown-label">Service Type</InputLabel>
//                 <Select
//                   labelId="dropdown-label"
//                   label="Select Option"
//                   required
//                 >
//                   <MenuItem value={1}>Option 1</MenuItem>
//                   <MenuItem value={2}>Option 2</MenuItem>
//                   <MenuItem value={3}>Option 3</MenuItem>
//                 </Select>
//               </FormControl>
//             </Grid>
//             <Grid item xs={12}>
//               <FormControl fullWidth variant="outlined">
//                 <InputLabel id="dropdown-label">Service Category</InputLabel>
//                 <Select
//                   labelId="dropdown-label"
//                   label="Select Option"
//                   required
//                 >
//                   <MenuItem value={1}>Option 1</MenuItem>
//                   <MenuItem value={2}>Option 2</MenuItem>
//                   <MenuItem value={3}>Option 3</MenuItem>
//                 </Select>
//               </FormControl>
//             </Grid>
//             <Grid item xs={6}>
//               <FormControl fullWidth variant="outlined">
//                 <InputLabel id="dropdown-label">Hours</InputLabel>
//                 <Select
//                   labelId="dropdown-label"
//                   label="Select Option"
//                   required
//                 >
//                   <MenuItem value={1}>Option 1</MenuItem>
//                   <MenuItem value={2}>Option 2</MenuItem>
//                   <MenuItem value={3}>Option 3</MenuItem>
//                 </Select>
//               </FormControl>
//             </Grid>
//             <Grid item xs={6}>
//               <FormControl fullWidth variant="outlined">
//                 <InputLabel id="dropdown-label">Minutes</InputLabel>
//                 <Select
//                   labelId="dropdown-label"
//                   label="Select Option"
//                   required
//                 >
//                   <MenuItem value={1}>Option 1</MenuItem>
//                   <MenuItem value={2}>Option 2</MenuItem>
//                   <MenuItem value={3}>Option 3</MenuItem>
//                 </Select>
//               </FormControl>
//             </Grid>
//             <Grid item xs={6}>
//               <TextField
//                 className='inputFormAbout'
//                 fullWidth
//                 label="Amount"
//                 variant="outlined"
//                 value={"45.00"}
//                 InputProps={{
//                   endAdornment: (
//                     <InputAdornment position="start">
//                       ₹
//                     </InputAdornment>
//                   ),
//                 }}
//               />
//             </Grid>
//             <Grid item xs={6}>
//               <TextField
//                 className='inputFormAbout'
//                 fullWidth
//                 label="Start At"
//                 variant="outlined"

//               />
//             </Grid>
//           </Grid>
//         </DialogContent>
//         <DialogActions>
//           <Grid container spacing={2} style={{ marginBottom: "20px", textAlign: 'center' }}><Grid item xs={6}>
//             <Button
//               variant="contained"
//               color='error'
//               sx={{
//                 mt: 4,
//                 width: '80%',
//                 padding: '10px',
//                 fontSize: '16px',
//                 marginLeft: "20px",
//                 marginTop: "10px"
//               }}
//               onClick={ handleCloseService }
//             >
//               Remove
//             </Button>
//           </Grid>
//             <Grid item xs={6}>
//               <Button
//                 variant="contained"
//                 color="primary"
//                 sx={{
//                   mt: 4,
//                   width: '80%',
//                   padding: '10px',
//                   fontSize: '16px',
//                   marginLeft: "20px",
//                   marginTop: "10px"
//                 }}
//                 onClick={() => { setViewType(14) }}
//               >
//                 Save
//               </Button>
//             </Grid>
//           </Grid>
//         </DialogActions>
//       </Dialog>
//     </Grid>

    <Grid container className={styles.loginBg}>
        <Grid size={6} className={styles.wrapContent}>            
            <div className={styles.formBox}>
                <div className={styles.formContent}>
                    <div className={`${styles.titleBox} ${styles.loginTitleBox}`}>
                        <h1>Login</h1>
                        <p>Enter your email address to login</p>
                    </div>
                    <div className={styles.inputBox}>
                        <TextField placeholder="Email Address" variant="outlined" fullWidth className={styles.inputBx} />
                        <Button variant="contained" className={styles.btn} onClick={Password}>Continue With Email</Button>
                    </div>
                    <div className={styles.divider}><span>OR</span></div>
                    <div className={styles.btnWrap}>
                        <Button variant="outlined" className={styles.btnSocial}><span><img src={icons.google} /></span>Continue with Google</Button>
                        <Button variant="outlined" className={styles.btnSocial}><span><img src={icons.facebook} /></span>Continue with Facebook</Button>
                    </div>
                </div> 
            </div> 
        </Grid>
        <Grid size={6} className={styles.companyBg}>
            <img src={icons.glam9Logo} />
        </Grid>        
    </Grid>
  );
};

export default Login;
