import React, { useState } from 'react';
import Grid from '@mui/material/Grid2';
import { Box, Paper, Typography, TextField, Button, InputProps, Divider, IconButton, LinearProgress, InputAdornment, FormControl, InputLabel, Select, MenuItem, FormControlLabel,FormHelperText, Checkbox, Radio, RadioGroup, Switch, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions} from '@mui/material';
import { icons } from '../../Assets/icons'
import { Link } from 'react-router-dom';
import styles from "./Login.module.scss";
import ProgressBar from './ProgressBar/ProgressBar';
import { useNavigate } from 'react-router-dom';

const HelpOne = () => {    
    const navigate = useNavigate();
    const helpTwo = () => {
      navigate('/help-two'); 
    };
  return (
    <Grid container className={styles.loginBg}>
        <Grid size={6} className={styles.wrapContent}>            
            <div className={`${styles.formBox} ${styles.formSmallBox}`}>             
                <div className={styles.backBtn}><img src={icons.backBtn} alt="" /></div> 
                <ProgressBar/>   
                <div className={styles.logoMobile}><img src={icons.glam9Logo} alt="" /></div>                   
                <div className={styles.formContent}>
                    <div className={styles.titleBox}>
                        <h1>How do you hope glam9 can help you?</h1>
                        <p>Choose up to 5</p>
                    </div>  
                        <div className={styles.labelWrap}>
                            <div className={styles.label}>More self-booked clients</div>
                            <div className={styles.label}>Selling products</div>
                            <div className={styles.label}>Engage clients</div>
                            <div className={styles.label}>Simplified payment processing</div>
                            <div className={styles.label}>Attract new clients</div>
                            <div className={styles.label}>Less cancelled or missed appointments</div>
                            <div className={styles.label}>Improve financial performance</div>
                            <div className={styles.label}>Social media integration</div>
                            <div className={styles.label}>Tracking business statistics</div>
                        </div>
                    
                    <Button variant="contained" className={styles.btn} onClick={helpTwo}>Continue</Button> 
                </div> 
            </div> 
        </Grid>
        <Grid size={6} className={styles.companyBg}>
            <img src={icons.glam9Logo} />
        </Grid>        
    </Grid>
  )
}

export default HelpOne



