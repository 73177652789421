import React, { useState } from 'react';
import Grid from '@mui/material/Grid2';
import { Box, Paper, Typography, TextField, Button, InputProps, Divider, IconButton, LinearProgress, InputAdornment, FormControl, InputLabel, Select, MenuItem, FormControlLabel,FormHelperText, Checkbox, Radio, RadioGroup, Switch, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions} from '@mui/material';
import { icons } from '../../Assets/icons'
import { Link } from 'react-router-dom';
import styles from "./Login.module.scss";
import ProgressBar from './ProgressBar/ProgressBar';

const HelpThree = () => {    
  return (
    <Grid container className={`${styles.loginBg} ${styles.loginFull}`}>
        <Grid size={12} className={styles.wrapContent}>            
            <div className={`${styles.formBox} ${styles.formWelcome}`}>             
                <div className={styles.logo}><img src={icons.glam9Logo} alt="" /></div>                   
                <div className={styles.contentBox}>
                    <div className={styles.titleBox}>
                        <h1>You’re all set</h1>
                        <p className={styles.welcomeText}>Welcome to the best tool for managing
                        your business.</p>                        
                    </div>  
                    <div className={styles.textContent}>
                        <p>Glam9 Biz Pro is designed to help you keep peace. Manage
                        team calendars, create shifts, process payments, run
                        reports, and check stock levels - allfrom the front desk.</p> 
                        <p>And, When you’re on the go? Us glam9 Biz on your mobile
                        devices to access core features and keep tabs
                        on your business from anywhere.</p>  
                    </div>    
                    <Button variant="contained" className={styles.btn}>Continue</Button> 
                </div> 
            </div> 
        </Grid>               
    </Grid>
  )
}

export default HelpThree



