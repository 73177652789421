import React from 'react';
import { Route, Routes } from "react-router-dom";
import Login from './Components/Auth/Login';
import Password from './Components/Auth/Password';
import ForgetPassword from './Components/Auth/ForgetPassword';
import YourBusiness from './Components/Auth/YourBusiness';
import AboutYou from './Components/Auth/AboutYou';
import WhereDoYouWork from './Components/Auth/WhereDoYouWork';
import EnterYourAddress from './Components/Auth/EnterYourAddress';
import ConfirmYourAddress from './Components/Auth/ConfirmYourAddress';
import BusinessLocation from './Components/Auth/BusinessLocation';
import Team from './Components/Auth/Team';
import BusinessHours from './Components/Auth/BusinessHours';
import AddServices from './Components/Auth/AddServices';
import Help from './Components/Auth/HelpOne';
import HelpOne from './Components/Auth/HelpOne';
import HelpTwo from './Components/Auth/HelpTwo';
import HelpThree from './Components/Auth/HelpThree';

function App() {
  return (
    <Routes>        
        <Route path="/" element={<Login />} />
        <Route path="/password" element={<Password />} />
        <Route path="/forget-password" element={<ForgetPassword />} />
        <Route path="/your-business" element={<YourBusiness />} />
        <Route path="/about-you" element={<AboutYou />} />
        <Route path="/where-do-you-work" element={<WhereDoYouWork />} />
        <Route path="/enter-your-address" element={<EnterYourAddress />} />
        <Route path="/confirm-your-address" element={<ConfirmYourAddress />} />
        <Route path="/business-location" element={<BusinessLocation />} />
        <Route path="/team" element={<Team />} />
        <Route path="/business-hours" element={<BusinessHours />} />
        <Route path="/add-services" element={<AddServices />} />
        <Route path="/help-one" element={<HelpOne />} />
        <Route path="/help-two" element={<HelpTwo />} />
        <Route path="/help-three" element={<HelpThree />} />
      </Routes>
  );
}

export default App;
