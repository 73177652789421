import React from 'react';
import { Box, Paper, Typography, TextField, Button, InputProps, Divider, IconButton, LinearProgress, InputAdornment, FormControl, InputLabel, Select, MenuItem, FormControlLabel,FormHelperText, Checkbox, Radio, RadioGroup, Switch, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions} from '@mui/material';
import Grid from '@mui/material/Grid2';
import styles from "./Login.module.scss";
import { icons } from '../../Assets/icons'
import { Link } from 'react-router-dom';
import ProgressBar from './ProgressBar/ProgressBar';
import { useNavigate } from 'react-router-dom';

function YourBusiness() {
    const navigate = useNavigate();
    const aboutYou = () => {
      navigate('/about-you'); 
    };
  return (
    <Grid container className={styles.loginBg}>
        <Grid size={6} className={styles.wrapContent}>            
            <div className={`${styles.formBox} ${styles.formSmallBox}`}>
                <div className={styles.backBtn}><img src={icons.backBtn} alt="" /></div> 
                <ProgressBar/>   
                <div className={styles.logoMobile}><img src={icons.glam9Logo} alt="" /></div>                
                <div className={`${styles.formContent} ${styles.formBigContent}`}>
                    <div className={styles.titleBox}>
                        <h1>What’s your business?</h1>
                        <p>Select the category you feel best represents your business</p>
                    </div>
                    <div className={styles.serviceWrap}>
                        <div className={styles.serviceBox}>
                            <div className={styles.serviceIcon}><img src={icons.service01} alt="" /></div>
                            <div className={styles.serviceText}>Nail Salon</div>
                        </div>
                        <div className={styles.serviceBox}>
                            <div className={styles.serviceIcon}><img src={icons.service01} alt="" /></div>
                            <div className={styles.serviceText}>Hair Salon</div>
                        </div>
                        <div className={styles.serviceBox}>
                            <div className={styles.serviceIcon}><img src={icons.service01} alt="" /></div>
                            <div className={styles.serviceText}>Brows & Lashes</div>
                        </div>
                        <div className={styles.serviceBox}>
                            <div className={styles.serviceIcon}><img src={icons.service01} alt="" /></div>
                            <div className={styles.serviceText}>Skin Care</div>
                        </div>
                        <div className={styles.serviceBox}>
                            <div className={styles.serviceIcon}><img src={icons.service01} alt="" /></div>
                            <div className={styles.serviceText}>Aesthetic Medicine</div>
                        </div>
                        <div className={styles.serviceBox}>
                            <div className={styles.serviceIcon}><img src={icons.service01} alt="" /></div>
                            <div className={styles.serviceText}>Barber</div>
                        </div>
                    </div>
                    <div className={styles.otherCategories}>
                        <div className={styles.title}>Other Categories</div>
                        <div className={styles.categoryWrap}>
                            <div className={styles.categoryLabel}>Braids & Locs</div>
                            <div className={styles.categoryLabel}>Hair Removal</div>
                            <div className={styles.categoryLabel}>Health & Fitness</div>
                            <div className={styles.categoryLabel}>Home Services</div>
                            <div className={styles.categoryLabel}>Make-up</div>
                            <div className={styles.categoryLabel}>Massage</div>
                            <div className={styles.categoryLabel}>Pet Services</div>
                            <div className={styles.categoryLabel}>Dental</div>
                        </div>
                    </div>
                    <Button variant="contained" className={styles.btn} onClick={aboutYou}>Continue</Button>                   
                </div>                 
            </div>             
        </Grid>
        <Grid size={6} className={styles.companyBg}>
            <img src={icons.glam9Logo} />
        </Grid>        
    </Grid>
  )
}

export default YourBusiness

