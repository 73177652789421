import React from 'react';
import { Box, Paper, Typography, TextField, Button, InputProps, Divider, IconButton, LinearProgress, InputAdornment, FormControl, InputLabel, Select, MenuItem, FormControlLabel,FormHelperText, Checkbox, Radio, RadioGroup, Switch, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions} from '@mui/material';
import Grid from '@mui/material/Grid2';
import styles from "./Login.module.scss";
import { icons } from '../../Assets/icons'
import { Link } from 'react-router-dom';
import ProgressBar from './ProgressBar/ProgressBar';
import { useNavigate } from 'react-router-dom';

function Password() {

    const navigate = useNavigate();
    const forgetPassword = () => {
      navigate('/forget-password'); 
    };
    
  return (
    <Grid container className={styles.loginBg}>
        <Grid size={6} className={styles.wrapContent}>            
            <div className={styles.formBox}>
                <div className={styles.backBtn}><img src={icons.backBtn} alt="" /></div> 
                <ProgressBar/>   
                <div className={styles.logoMobile}><img src={icons.glam9Logo} alt="" /></div>               
                <div className={styles.formContent}>
                    <div className={styles.titleBox}>
                        <h1>Password</h1>
                        <p>It looks like there’s already a profile associated
                        with this email address</p>
                    </div>
                    <div className={styles.inputBox}>   
                        <div className={styles.inputWrap}>                     
                            <TextField placeholder ="Password" type="password" fullWidth className={styles.inputBx} />
                            <span className={styles.showText}>Show</span>
                        </div>
                        {/* <div className={styles.passwordContain}>
                            <div className={styles.text}>The password must contain</div>
                            <ul>
                                <li>
                                    <span>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.4205 6.82693C14.7256 7.13211 14.7256 7.6268 14.4205 7.93182L9.17938 13.1731C8.87421 13.4781 8.37967 13.4781 8.07449 13.1731L5.57953 10.6779C5.27435 10.3729 5.27435 9.87823 5.57953 9.57321C5.88455 9.26804 6.37924 9.26804 6.68427 9.57321L8.62686 11.5158L13.3156 6.82693C13.6208 6.52191 14.1154 6.52191 14.4205 6.82693ZM20 10C20 15.5275 15.5267 20 10 20C4.4725 20 0 15.5267 0 10C0 4.4725 4.47327 0 10 0C15.5275 0 20 4.47327 20 10ZM18.4375 10C18.4375 5.33615 14.6632 1.5625 10 1.5625C5.33615 1.5625 1.5625 5.33676 1.5625 10C1.5625 14.6638 5.33676 18.4375 10 18.4375C14.6638 18.4375 18.4375 14.6632 18.4375 10Z" fill="#414141"/>
                                        </svg>
                                    </span>at least one letter</li>
                                <li><span>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.4205 6.82693C14.7256 7.13211 14.7256 7.6268 14.4205 7.93182L9.17938 13.1731C8.87421 13.4781 8.37967 13.4781 8.07449 13.1731L5.57953 10.6779C5.27435 10.3729 5.27435 9.87823 5.57953 9.57321C5.88455 9.26804 6.37924 9.26804 6.68427 9.57321L8.62686 11.5158L13.3156 6.82693C13.6208 6.52191 14.1154 6.52191 14.4205 6.82693ZM20 10C20 15.5275 15.5267 20 10 20C4.4725 20 0 15.5267 0 10C0 4.4725 4.47327 0 10 0C15.5275 0 20 4.47327 20 10ZM18.4375 10C18.4375 5.33615 14.6632 1.5625 10 1.5625C5.33615 1.5625 1.5625 5.33676 1.5625 10C1.5625 14.6638 5.33676 18.4375 10 18.4375C14.6638 18.4375 18.4375 14.6632 18.4375 10Z" fill="#414141"/>
                                        </svg>
                                    </span>at least one digit</li>
                                <li><span>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.4205 6.82693C14.7256 7.13211 14.7256 7.6268 14.4205 7.93182L9.17938 13.1731C8.87421 13.4781 8.37967 13.4781 8.07449 13.1731L5.57953 10.6779C5.27435 10.3729 5.27435 9.87823 5.57953 9.57321C5.88455 9.26804 6.37924 9.26804 6.68427 9.57321L8.62686 11.5158L13.3156 6.82693C13.6208 6.52191 14.1154 6.52191 14.4205 6.82693ZM20 10C20 15.5275 15.5267 20 10 20C4.4725 20 0 15.5267 0 10C0 4.4725 4.47327 0 10 0C15.5275 0 20 4.47327 20 10ZM18.4375 10C18.4375 5.33615 14.6632 1.5625 10 1.5625C5.33615 1.5625 1.5625 5.33676 1.5625 10C1.5625 14.6638 5.33676 18.4375 10 18.4375C14.6638 18.4375 18.4375 14.6632 18.4375 10Z" fill="#414141"/>
                                        </svg>
                                    </span>8 charaacters or more</li>
                            </ul>
                        </div> */}
                        <Button variant="contained" className={styles.btn} onClick={forgetPassword}>Continue</Button>
                    </div>
                    <p>By signing up i agree to the <Link to="/">Terms & Conditions </Link> 
                and to the <Link to="/">Privacy Policy</Link></p>
                </div>                 
            </div>             
        </Grid>
        <Grid size={6} className={styles.companyBg}>
            <img src={icons.glam9Logo} />
        </Grid>        
    </Grid>
  )
}

export default Password
