import React from 'react';
import Grid from '@mui/material/Grid2';
import { Box, Paper, Typography, TextField, Button, InputProps, Divider, IconButton, LinearProgress, InputAdornment, FormControl, InputLabel, Select, MenuItem, FormControlLabel,FormHelperText, Checkbox, Radio, RadioGroup, Switch, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions} from '@mui/material';
import { icons } from '../../Assets/icons'
import { SelectChangeEvent } from '@mui/material/Select';
import styles from "./Login.module.scss";
import ProgressBar from './ProgressBar/ProgressBar';
import { images } from '../../Assets/images';
import { useNavigate } from 'react-router-dom';


const BusinessLocation = () => {
    const navigate = useNavigate();
    const team = () => {
      navigate('/team'); 
    };
  return (
    <Grid container className={styles.loginBg}>
    <Grid size={6} className={styles.wrapContent}>            
        <div className={`${styles.formBox} ${styles.formBigBox}`}>
            <div className={styles.backBtn}><img src={icons.backBtn} alt="" /></div>  
            <ProgressBar />   
            <div className={styles.logoMobile}><img src={icons.glam9Logo} alt="" /></div> 
            <div className={`${styles.formContent} ${styles.formBigContent}`}>
                <div className={styles.titleBox}>
                    <h1>Do You Work Here?</h1>
                    <p>If so we’ll link your Glam9 profile to this shared location. Clients will be able to find you and book you through
                        either profile page.</p>
                </div> 
                <div className={styles.locationBox}>
                    <img src={images.LocationMap} alt="" />                    
                </div>                  
                <div className={styles.inputBox}>
                    <TextField placeholder="Suit Number" variant="outlined" fullWidth className={styles.inputBx} />                        
                </div>                              
                <div className={styles.wrapBtns}>
                    <Button variant="contained" className={`${styles.btn} ${styles.btnBorder}`}>Reset</Button>                    
                    <Button variant="contained" className={styles.btn} onClick={team}>Continue</Button>
                </div> 
            </div> 
        </div> 
    </Grid>
    <Grid size={6} className={styles.companyBg}>
        <img src={icons.glam9Logo} alt='' />
    </Grid>        
</Grid>
  )
}

export default BusinessLocation
