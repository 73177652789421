import React from 'react';
import { Box, Paper, Typography, TextField, Button, InputProps, Divider, IconButton, LinearProgress, InputAdornment, FormControl, InputLabel, Select, MenuItem, FormControlLabel,FormHelperText, Checkbox, Radio, RadioGroup, Switch, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions} from '@mui/material';
import Grid from '@mui/material/Grid2';
import styles from "./Login.module.scss";
import { icons } from '../../Assets/icons'
import { Link } from 'react-router-dom';
import ProgressBar from './ProgressBar/ProgressBar';
import { useNavigate } from 'react-router-dom';

function ForgetPassword() {
    const navigate = useNavigate();
    const yourBusiness = () => {
      navigate('/your-business'); 
    };
  return (
    <Grid container className={styles.loginBg}>
        <Grid size={6} className={styles.wrapContent}>            
            <div className={styles.formBox}>
                <div className={styles.backBtn}><img src={icons.backBtn} alt="" /></div>  
                <ProgressBar />   
                <div className={styles.logoMobile}><img src={icons.glam9Logo} alt="" /></div>              
                <div className={styles.formContent}>
                    <div className={styles.titleBox}>
                        <h1>Forget Password</h1>
                        <p>Enter the email associated with your Glam9 account.</p>
                    </div>
                    <div className={styles.inputBox}>   
                        <div className={styles.inputWrap}>                     
                        <TextField placeholder="Email Address" variant="outlined" fullWidth className={styles.inputBx} />                            
                        </div>                        
                        <Button variant="contained" className={styles.btn} onClick={yourBusiness}>Reset Password</Button>
                    </div>                    
                </div>                 
            </div>             
        </Grid>
        <Grid size={6} className={styles.companyBg}>
            <img src={icons.glam9Logo} />
        </Grid>        
    </Grid>
  )
}

export default ForgetPassword
