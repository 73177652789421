import { createTheme } from '@mui/material/styles';
import './Home.scss'

const theme = createTheme({
  palette: {
    primary: {
      main: '#4E297B', // Blue color for primary
    },
    secondary: {
      main: '#dc004e', // Red color for secondary
    },
    text: {
      primary: '#231F20', // Defining black for text color
    },
  },
  typography: {
    fontFamily: 'Poppins, sans-serif', // Defining the font family   
    body1: {
      fontSize: '16px', // Example for body text
      color: '#231F20', // Reference color from palette or directly use hex
    },
  },
  components: {
    MuiSwitch: {
      styleOverrides: {
        root: {          
          padding: 10,
          marginRight: 10,
        },
        switchBase: {            
          color: 'white', // Thumb color when unchecked
          '&.Mui-checked': {
            color: 'white', // Thumb color when checked            
          },          
          '&.Mui-checked + .MuiSwitch-track': {
            backgroundColor: '#4E297B', // Track color when checked
            opacity: 1,
            
          },
        },
        thumb: {
          width: 18,
          height: 18,
          marginTop: 3,
          
        },
        track: {
          backgroundColor: 'lightgray', // Track color when unchecked
          opacity: 1, // Ensure visibility of track color
          borderRadius: 30,
          height: 22, 
          minWidth: 39,
        },
      },
    },
  },

});

export default theme;
