import React, {useState} from 'react';
import Grid from '@mui/material/Grid2';
import { Box, Paper, Typography, TextField, Button, InputProps, Divider, IconButton, LinearProgress, InputAdornment, FormControl, InputLabel, Select, MenuItem, FormControlLabel,FormHelperText, Checkbox, Radio, RadioGroup, Switch, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions} from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { icons } from '../../Assets/icons'
import Modal from '@mui/material/Modal';
import ProgressBar from './ProgressBar/ProgressBar';
import styles from "./Login.module.scss"
import { useNavigate } from 'react-router-dom';

const BusinessHours = () => {    
    const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();
  const addServices = () => {
    navigate('/add-services'); 
  };
 
  return (    
    <>
    <Grid container className={styles.loginBg}>
        <Grid size={6} className={styles.wrapContent}>            
            <div className={`${styles.formBox} ${styles.formBigBox}`}>
                <div className={styles.backBtn}><img src={icons.backBtn} alt="" /></div>  
                <ProgressBar />   
                <div className={styles.logoMobile}><img src={icons.glam9Logo} alt="" /></div> 
                <div className={`${styles.formContent} ${styles.formBigContent}`}>
                    <div className={styles.titleBox}>
                        <h1>your business hours</h1>       
                        <p>Where can clients find you?</p>             
                    </div>    
                    <div className={styles.hoursWrap}>                 
                        <div className={styles.hoursBox}>
                            <div className={styles.days}>                            
                                <FormControlLabel className="customSwitch" control={<Switch defaultChecked />} label="Monday" classes={{ root: styles.switchLabel }} />                            
                            </div> 
                            <div className={styles.time}>10:00 - 19:00</div>  
                            <div className={styles.arrowBox} onClick={handleOpen}><img src={icons.hoursArrow} /></div>
                        </div>
                        <div className={styles.hoursBox}>
                            <div className={styles.days}>                            
                                <FormControlLabel className="customSwitch"  control={<Switch defaultChecked />} label="Monday" />                            
                            </div> 
                            <div className={styles.time}>10:00 - 19:00</div>  
                            <div className={styles.arrowBox}><img src={icons.hoursArrow} /></div>
                        </div>
                        <div className={styles.hoursBox}>
                            <div className={styles.days}>                            
                                <FormControlLabel className="customSwitch" control={<Switch defaultChecked />} label="Monday" />                            
                            </div> 
                            <div className={styles.time}>10:00 - 19:00</div>  
                            <div className={styles.arrowBox}><img src={icons.hoursArrow} /></div>
                        </div>
                        <div className={styles.hoursBox}>
                            <div className={styles.days}>                            
                                <FormControlLabel className="customSwitch" control={<Switch defaultChecked />} label="Monday" />                            
                            </div> 
                            <div className={styles.time}>10:00 - 19:00</div>  
                            <div className={styles.arrowBox}><img src={icons.hoursArrow} /></div>
                        </div>
                        <div className={styles.hoursBox}>
                            <div className={styles.days}>                            
                                <FormControlLabel className="customSwitch" control={<Switch defaultChecked />} label="Monday" />                            
                            </div> 
                            <div className={styles.time}>10:00 - 19:00</div>  
                            <div className={styles.arrowBox}><img src={icons.hoursArrow} /></div>
                        </div>
                        <div className={styles.hoursBox}>
                            <div className={styles.days}>                            
                                <FormControlLabel className="customSwitch" control={<Switch defaultChecked />} label="Monday" />                            
                            </div> 
                            <div className={styles.time}>10:00 - 19:00</div>  
                            <div className={styles.arrowBox}><img src={icons.hoursArrow} /></div>
                        </div>                     

                    </div>
                    <Button variant="contained" className={styles.btn} onClick={addServices}>Continue</Button>
                </div> 
            </div> 
        </Grid>
    <Grid size={6} className={styles.companyBg}>
        <img src={icons.glam9Logo} alt='' />
    </Grid>        
</Grid>


<Modal
  open={open}
  onClose={handleClose}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <div>
    <Typography id="modal-modal-title" variant="h6" component="h2">
      Text in a modal
    </Typography>
    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
      Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
    </Typography>
  </div>
</Modal>
</>

  )
}

export default BusinessHours
